import React from "react";
import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import Loader from "../../../layout/GenericLayout/Loader";
import TablePagination from "../../../layout/GenericLayout/TablePagination";

const LenderDisbursedInvoiceTable = (props) => {
  const updatePage = (currentPage) => {
    const newOffSet = props.tableQueryParams.tableLimit * (currentPage - 1);
    console.log("Updating page:", currentPage, "Offset:", newOffSet);
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableOffset: newOffSet,
      currentPage,
    }));
  };

  const onClickViewMoreTableData = () => {
    let newLimit = props.tableQueryParams.tableLimit;
    if (newLimit === 5) {
      newLimit += 5;
    } else {
      newLimit -= 5;
    }    
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableLimit: newLimit,
    }));
  };

  const isEmptyTable = !props.isLoading && props.tableRows.length === 0;

  return (
    <>
      <div className="lender-table-container">
        <div className="table-inner-cont">
          <table className={props.className}>
            <thead>
              <tr>
                {props.tableHeader.map((eachHeader) => (
                  <th key={eachHeader.name} scope="col">
                    {eachHeader.name}
                  </th>
                ))}
              </tr>
            </thead>
            {isEmptyTable ? (
              <tbody>
                <tr>
                  <td colSpan={props.tableHeader.length} className="text-danger">
                    Oops! There is no data to show. Please check back later.
                  </td>
                </tr>
              </tbody>
            ) : props.isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={props.tableHeader.length}>
                    <Loader className="my-5" />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {props.tableRows.map((eachRow, index) => (
                  <tr key={index}>
                    <td>{eachRow.sellerName}</td>
                    <td>{eachRow.buyerName}</td>
                    <td>{eachRow.invoiceId}</td>
                    <td>{eachRow.externalInvoiceId || "N/A"}</td>
                    <td>{eachRow.billReferenceNo}</td>
                    <td>{eachRow.paymentId}</td>
                    <td>{eachRow.contractNo}</td>
                    <td>{eachRow.date}</td>
                    <td>{eachRow.invoiceAmount}</td>
                    <td>{eachRow.disbursedOn}</td>
                    <td>{eachRow.invoiceStatus}</td>
                    <td>{eachRow.disbursedAmount}</td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>

      {/* View More / View Less Section */}
      {!props.isLoading && props.materialRequestMetaData?.paging?.total > 5 && (
        <div className="d-flex justify-content-end px-2">
          <span
            onClick={onClickViewMoreTableData}
            className="d-flex align-items-center gap-1 my-3 view-more-text"
          >
            {props.tableQueryParams.tableLimit === 5 ? "View More" : "View Less"}
            <HiOutlineArrowNarrowDown
              className={`fs-5 ${
                props.tableQueryParams.tableLimit === 5
                  ? "down-arrow"
                  : "up-arrow"
              }`}
            />
          </span>
        </div>
      )}

      {/* Pagination Section */}
      {!props.isLoading && (
        <div className="d-flex align-items-center gap-2 page-data-text-container mt-3 px-3 w-100">
          <TablePagination
            pageSize={props.tableQueryParams.tableLimit}
            onUpdatePage={updatePage}
            current={props.tableQueryParams.currentPage}
            total={props.materialRequestMetaData?.paging?.total || 0}
          />
        </div>
      )}
    </>
  );
};

export default LenderDisbursedInvoiceTable;
