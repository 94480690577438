import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../utilities/api";
import axios from "axios";
import { CAM_URL, DOC_URL, BASE_URL, baseUrl } from "../../utilities/config";

const initialState = {
  /* LOG IN RESTRICITON */
  isLogoutData: false,
  /* SIGN IN */
  loginData: {
    data: null,
    isLoading: false,
    error: null,
  },

  /* SIGN UP */
  signUpData: {
    data: null,
    isLoading: false,
    error: null,
  },

  /* FORGOT PASSWORD */
  myprofileData: {
    data: null,
    isLoading: false,
    error: null,
  },

  forgotPasswordData: {
    data: null,
    isLoading: false,
    error: null,
  },

  /* OTHERS */
  isEmailRegisteredData: {
    data: null,
    isLoading: false,
    error: null,
  },

  uploadDocuments: {
    data: null,
    isLoading: false,
    error: null,
  },

  selectLenderData: {
    data: null,
    isLoading: false,
    error: null,
  },

  userKYCData: {
    data: null,
    isLoading: false,
    error: null,
  },

  userKYCDetails: {
    data: null,
    isLoading: false,
    error: null,
  },

  userProfilePictureData: {
    data: null,
    isLoading: false,
    error: null,
  },

  uploadKYCDocuments: {
    data: null,
    isLoading: false,
    error: null,
  },

  uploadAadharOCRDetails: {
    data: null,
    isLoading: false,
    error: null,
  },

  scoreCardByEmailIdData: {
    data: null,
    isLoading: false,
    error: null,
  },

  uploadPANVerificationData: {
    data: null,
    isLoading: false,
    error: null,
  },

  deleteFileData: {
    data: null,
    isLoading: false,
    error: null,
  },

  scoreCardDetails: {
    data: null,
    isLoading: false,
    error: null,
  },

  resetPasswordData: {
    data: null,
    isLoading: false,
    error: null,
  },

  distributorsList: {
    data: null,
    isLoading: false,
    error: null,
  },
  getOTPForLogin: {
    data: null,
    isLoading: false,
    error: null,
  },
  emailTokenVerification: {
    data: null,
    isLoading: false,
    error: false,
  },
  resetForgotPassword: { data: null, isLoading: false, error: null },
  postEquifax:{data: null, isLoading: false, error: null},
  profilePictureData:{data: null, isLoading: false, error: null},
  approveUser:{data: null, isLoading: false, error: null},
  rejectUser:{data: null, isLoading: false, error: null},
};


export const profilePicture = createAsyncThunk(
  "get/profilePicture",
  async (body) => {
    try {
      const url = `${DOC_URL}/kyc/docs/${sessionStorage.getItem("email")}/profilePicture`;
      const response = await axios.get(url, body);
      return response.data;
    } catch (error) {
      // <ToastMessage message={"error"} type="success" />;
      throw new Error(error.response.data.error);
    }
  }
);


export const resetForgotPassword = createAsyncThunk(
  "post/resetForgotPassword",
  async (payload) => {
    try {
      const { data } = await api.post("user/resetpassword", payload);

      return data;
    }
    catch (err) {
      throw new Error(err?.response?.data?.error ?? "Unknown Error Occurred");
    }
  }
);

export const verifyEmailToken = createAsyncThunk(
  "post/verifyEmailToken",
  async (token) => {
    try {
      const { data } = await api.get("user/confirm_token/" + token);
      return data;
    }
    catch (error) {
      throw new Error(error?.response?.data?.error ?? "")
    }
  }
);

export const approveUserToken = createAsyncThunk(
  "post/approveUserToken",
  async (token) => {
    try {
      const { data } = await api.get("user/approve_token/" + token);
      return data;
    }
    catch (error) {
      throw new Error(error?.response?.data?.error ?? "")
    }
  }
);

export const rejectUserToken = createAsyncThunk(
  "post/rejectUserToken",
  async (token) => {
    try {
      const { data } = await api.get("user/reject_token/" + token);
      return data;
    }
    catch (error) {
      throw new Error(error?.response?.data?.error ?? "")
    }
  }
);


/* SIGN IN */
export const updateLoginData = createAsyncThunk(
  "update/loginData",
  async (body) => {
    try {
      const url = `user/logIn`;
      const response = await api.post(url, body);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const getOTPForLogin = createAsyncThunk(
  "get/loginOTP",
  async (body) => {
    try {
      const url = "user/otp";
      const { data } = await api.post(url, body);

      return data;
    }
    catch (err) {
      throw new Error(err.response.data.error);
    }
  }
);

export const otpLogin = createAsyncThunk(
  "post/otpLogin",
  async (body) => {
    try {
      const url = "user/otplogin";
      
      const { data } = await api.post(url, body);

      return data;
    }
    catch (err) {
      throw new Error(err.response.data.error);
    }
  }
);

export const getScoreCardDataByEmailId = createAsyncThunk(
  "get/scoreCardDataByEmailId",
  async (emailId) => {
    try {
      const url = `user/getdetails/${emailId}`;
      // const url = `${baseUrl}user/getdetails/xatag64888@inkiny.com`;
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
/* SIGN UP */
export const updateSignUp = createAsyncThunk("update/signUp", async (body) => {
  try {
    const url = `user/register`;
    const response = await api.post(url, body);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
});

/* FORGOT PASSWORD */
export const updateForgotPassword = createAsyncThunk(
  "update/forgotPassword",
  async (body) => {
    try {
      const url = `user/forgotpassword`;
      const response = await api.post(url, body);
      return response.data;
    } catch (error) {
      // <ToastMessage message={"error"} type="success" />;
      throw new Error(error.response.data.error);
    }
  }
);

/* OTHERS */
export const getMyProfile = createAsyncThunk("get/myProfile", async () => {
  try {
    const emailId = sessionStorage.getItem("email");
    const head = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
    };
    const url = `user/getdetails/${emailId}`;
    const response = await api.get(url, head);
    return response.data;
  } catch (error) {
    console.log("err : ", error);
    throw new Error(error.response.data.error);
  }
});

export const updateResetPassowrd = createAsyncThunk(
  "update/resetPassword",
  async (body) => {
    try {
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const url = `user/reset__password`;
      const response = await api.post(url, body, head);
      return response.data;
    } catch (error) {
      console.log("err : ", error);
      throw new Error(error.response.data.error);
    }
  }
);

export const getIsEmailRegistered = createAsyncThunk(
  "get/isEmailRegistered",
  async (emailId) => {
    try {
      const url = `user/isRegistered/${emailId}`;
      const response = await api.get(url, emailId);
      return response.data;
    } catch (error) {
      // <ToastMessage message={"error"} type="success" />;
      throw new Error(error.response.data.error);
    }
  }
);

export const updateUploadDocuments = createAsyncThunk(
  "update/uploadDocuments",
  async (body) => {
    try {
      const url = `${DOC_URL}/upload_docs`;
      const response = await axios.post(url, body);
      return response.data;
    } catch (error) {
      // <ToastMessage message={"error"} type="success" />;
      throw new Error(error.response.data.error);
    }
  }
);

export const getLenderList = createAsyncThunk("get/lenderList", async () => {
  try {
    const url = "user/list_of_banks";
    // const response = await api.get(url, {
    //   headers: {
    //     Authorization: "Bearer " + sessionStorage.getItem("token"),
    //   },
    // });
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
});

export const getUserKYCDocument = createAsyncThunk(
  "get/userKYCDocument",
  async () => {
    try {
      const url = "user/kyc";
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const getUserKYCDetails = createAsyncThunk(
  "get/userKYCDetails",
  async () => {
    try {
      const url = "user/kyc";

      const { data } = await api.get(url, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      });

      return data;
    } catch (error) {
      throw new Error(error?.response?.data?.error);
    }
  }
);

export const getAllDistributors = createAsyncThunk(
  "user/get_all_distributors",
  async () => {
    // console.log('I am inside Redux Invoice accept Details: ',invoiceNumSellerID)
    try {
      // let url=''
      let url = `user/get_all_distributors`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const updateUploadProfilePicture = createAsyncThunk(
  "update/uploadProfilePicture",
  async (body) => {
    try {
      const url = `${DOC_URL}kyc/docs_for_cp`;
      const response = await axios.post(url, body);
      return response.data;
    } catch (error) {
      // <ToastMessage message={"error"} type="success" />;
      throw new Error(error.response.data.error);
    }
  }
);

export const updateUploadKYCDocuments = createAsyncThunk(
  "update/uploadKYCDocuments",
  async (body) => {
    try {
      const url = `${DOC_URL}kyc/docs`;
      const head = {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      };
      const response = await axios.post(url, body, head);
      return response.data;
    } catch (error) {
      // <ToastMessage message={"error"} type="success" />;
      throw new Error(error.response.data.error);
    }
  }
);

export const updateAadharOCRDetails = createAsyncThunk(
  "update/aadharOCRDetails",
  async (body) => {
    try {
      const url = `${BASE_URL}cam/kyc_orc_aadhar`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };

      const formData= new FormData();
      formData.append("file",body.get("adharImage")??body.get("coAadharImage"));
      formData.append("userid",body.get("emailId"));

      const response = await api.post(url, formData, head);
      return response.data;
    } catch (error) {
      // <ToastMessage message={"error"} type="success" />;
      throw new Error(error.response.data.message);
    }
  }
);

export const updatePANVerification = createAsyncThunk(
  "update/PANVerification",
  async (body) => {
    try {
      const url = `${BASE_URL}cam/pan_verification`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.post(url, body, head);
      return response.data;
    } catch (error) {
      console.log();
      throw new Error(error.response.data.message);
    }
  }
);

export const updateDeleteFile = createAsyncThunk(
  "update/deleteFile",
  async (body) => {
    try {
      const url = `${DOC_URL}kyc/delete_file`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await axios.put(url, body, head);
      return response.data;
    } catch (error) {
      console.log();
      throw new Error(error.response.data.message);
    }
  }
);

export const getScoreCardDetails = createAsyncThunk(
  "get/scoreCardDetailsByEmailId",
  async (emailId) => {
    try {
      const url = `cam/get_score/${emailId}`;
      // const url = `${baseUrl}cam/get_score/xatag64888@inkiny.com`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const postEquifaxscore = createAsyncThunk(
  "post/Equifaxscore",
  async (obj) => {
    try {
      var url;
      console.log(obj);
      if(obj.role==="buyer"){
        url = `cam/get_equifax_score_for_retailer`;
      }else if(obj.role==='seller'){
        url = `cam/get_equifax_score_for_distributor`;
      }
      const body={emailId:obj.emailId}
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}`},
      };
      const response = await api.post(url,body,head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);


const genericSlice = createSlice({
  name: "generic",
  initialState,
  reducers: {
    resetGenericState: (state) => initialState,
    isLogout: (state) => {
      state.isLogoutData = true;
    },
  },
  extraReducers(builder) {
    builder
      /* SIGN IN */
      /* ***update login data*** */
      .addCase(updateLoginData.pending, (state, action) => {
        state.loginData.isLoading = true;
      })
      .addCase(updateLoginData.fulfilled, (state, action) => {
        state.loginData.data = action.payload;

        state.loginData.isLoading = false;
      })
      .addCase(updateLoginData.rejected, (state, action) => {
        state.loginData.error = action.error.message;
        state.loginData.isLoading = false;
      })
      
      .addCase(otpLogin.pending, (state, action) => {
        state.loginData.isLoading = true;
      })
      .addCase(otpLogin.fulfilled, (state, action) => {
        state.loginData.data = action.payload;

        state.loginData.isLoading = false;
      })
      .addCase(otpLogin.rejected, (state, action) => {
        state.loginData.error = action.error.message;
        state.loginData.isLoading = false;
      })


      /* GET SCORE CARD DETAILS BY EMAIL ID */
      .addCase(getScoreCardDataByEmailId.pending, (state, action) => {
        state.scoreCardByEmailIdData.isLoading = true;
      })
      .addCase(getScoreCardDataByEmailId.fulfilled, (state, action) => {
        state.scoreCardByEmailIdData.data = action.payload;
        state.scoreCardByEmailIdData.isLoading = false;
      })
      .addCase(getScoreCardDataByEmailId.rejected, (state, action) => {
        state.scoreCardByEmailIdData.error = action.error.message;
        state.scoreCardByEmailIdData.isLoading = false;
      })

      .addCase(getAllDistributors.pending, (state, action) => {
        state.distributorsList.isLoading = true;
      })
      .addCase(getAllDistributors.fulfilled, (state, action) => {
        state.distributorsList.data = action.payload;
        state.distributorsList.isLoading = false;
      })
      .addCase(getAllDistributors.rejected, (state, action) => {
        state.distributorsList.error = action.error.message;
        state.distributorsList.isLoading = false;
      })

      /* SIGN UP */
      .addCase(updateSignUp.pending, (state, action) => {
        state.signUpData.isLoading = true;
      })
      .addCase(updateSignUp.fulfilled, (state, action) => {
        state.signUpData.data = action.payload;
        state.signUpData.isLoading = false;
      })
      .addCase(updateSignUp.rejected, (state, action) => {
        state.signUpData.error = action.error.message;
        state.signUpData.isLoading = false;
      })

      /* ***UPDATE FORGOT PASSWORD*** */
      .addCase(updateForgotPassword.pending, (state, actions) => {
        state.forgotPasswordData.isLoading = true;
      })
      .addCase(updateForgotPassword.fulfilled, (state, actions) => {
        state.forgotPasswordData.data = actions.payload;
        state.forgotPasswordData.isLoading = false;
      })
      .addCase(updateForgotPassword.rejected, (state, actions) => {
        state.forgotPasswordData.error = actions.error.message;
        state.forgotPasswordData.isLoading = false;
      })

      /* ******OTHERS****** */
      /* MY PROFILE */
      .addCase(getMyProfile.pending, (state, actions) => {
        state.myprofileData.isLoading = true;
      })
      .addCase(getMyProfile.fulfilled, (state, actions) => {
        state.myprofileData.data = actions.payload;
        state.myprofileData.isLoading = false;
      })
      .addCase(getMyProfile.rejected, (state, actions) => {
        state.myprofileData.error = actions.error.message;
        state.myprofileData.isLoading = false;
      })

      /* RESET PASSWORD */
      .addCase(updateResetPassowrd.pending, (state, actions) => {
        state.resetPasswordData.isLoading = true;
      })
      .addCase(updateResetPassowrd.fulfilled, (state, actions) => {
        state.resetPasswordData.data = actions.payload;
        state.resetPasswordData.isLoading = false;
      })
      .addCase(updateResetPassowrd.rejected, (state, actions) => {
        state.resetPasswordData.error = actions.error.message;
        state.resetPasswordData.isLoading = false;
      })
      /* ***get is email register*** */
      .addCase(getIsEmailRegistered.pending, (state, actions) => {
        state.isEmailRegisteredData.data = null;
        state.isEmailRegisteredData.error = null;
        state.isEmailRegisteredData.isLoading = true;
      })
      .addCase(getIsEmailRegistered.fulfilled, (state, actions) => {
        state.isEmailRegisteredData.data = actions.payload;
        state.isEmailRegisteredData.error = null;
        state.isEmailRegisteredData.isLoading = false;
      })
      .addCase(getIsEmailRegistered.rejected, (state, actions) => {
        state.isEmailRegisteredData.error = actions.error.message;
        state.isEmailRegisteredData.data = null;
        state.isEmailRegisteredData.isLoading = false;
      })
      /* GET SELECT LENDER DATA */
      .addCase(getLenderList.pending, (state, action) => {
        state.selectLenderData.isLoading = true;
      })
      .addCase(getLenderList.fulfilled, (state, action) => {
        state.selectLenderData.data = action.payload;
        state.selectLenderData.isLoading = false;
      })
      .addCase(getLenderList.rejected, (state, action) => {
        state.selectLenderData.error = action.error.message;
        state.selectLenderData.isLoading = false;
      })

      /* ***FILE UPLOAD (LR)*** */
      .addCase(updateUploadDocuments.pending, (state, actions) => {
        state.uploadDocuments.isLoading = true;
      })
      .addCase(updateUploadDocuments.fulfilled, (state, actions) => {
        state.uploadDocuments.data = actions.payload;
        state.uploadDocuments.isLoading = false;
      })
      .addCase(updateUploadDocuments.rejected, (state, actions) => {
        state.uploadDocuments.error = actions.error.message;
        state.uploadDocuments.isLoading = false;
      })

      /* USER KYC DOCUMENT */
      .addCase(getUserKYCDocument.pending, (state, actions) => {
        state.userKYCData.isLoading = true;
      })
      .addCase(getUserKYCDocument.fulfilled, (state, actions) => {
        state.userKYCData.data = actions.payload;
        state.userKYCData.isLoading = false;
      })
      .addCase(getUserKYCDocument.rejected, (state, actions) => {
        state.userKYCData.error = actions.error.message;
        state.userKYCData.isLoading = false;
      })

      .addCase(getUserKYCDetails.pending, (state, actions) => {
        state.userKYCDetails.isLoading = true;
      })
      .addCase(getUserKYCDetails.fulfilled, (state, actions) => {
        state.userKYCDetails.data = actions.payload;
        state.userKYCDetails.isLoading = false;
      })
      .addCase(getUserKYCDetails.rejected, (state, actions) => {
        state.userKYCDetails.error = actions.error.message;
        state.userKYCDetails.isLoading = false;
      })

      /* USER PROFILE PICTURE */
      .addCase(updateUploadProfilePicture.pending, (state, actions) => {
        state.userProfilePictureData.isLoading = true;
      })
      .addCase(updateUploadProfilePicture.fulfilled, (state, actions) => {
        state.userProfilePictureData.data = actions.payload;
        state.userProfilePictureData.isLoading = false;
      })
      .addCase(updateUploadProfilePicture.rejected, (state, actions) => {
        state.userProfilePictureData.error = actions.error.message;
        state.userProfilePictureData.isLoading = false;
      })

      /* UPLAOD KYC DOCUMENT */
      .addCase(updateUploadKYCDocuments.pending, (state, actions) => {
        state.uploadKYCDocuments.isLoading = true;
      })
      .addCase(updateUploadKYCDocuments.fulfilled, (state, actions) => {
        state.uploadKYCDocuments.data = actions.payload;
        state.uploadKYCDocuments.isLoading = false;
      })
      .addCase(updateUploadKYCDocuments.rejected, (state, actions) => {
        state.uploadKYCDocuments.error = actions.error.message;
        state.uploadKYCDocuments.isLoading = false;
      })

      /* UPLAOD AADHAR OCR DETAILS */
      .addCase(updateAadharOCRDetails.pending, (state, actions) => {
        state.uploadAadharOCRDetails.isLoading = true;
      })
      .addCase(updateAadharOCRDetails.fulfilled, (state, actions) => {
        state.uploadAadharOCRDetails.data = actions.payload;
        state.uploadAadharOCRDetails.isLoading = false;
      })
      .addCase(updateAadharOCRDetails.rejected, (state, actions) => {
        state.uploadAadharOCRDetails.error = actions.error.message;
        state.uploadAadharOCRDetails.isLoading = false;
      })

      /* UPLAOD PAN VERIFICATION DETAILS */
      .addCase(updatePANVerification.pending, (state, actions) => {
        state.uploadPANVerificationData.isLoading = true;
      })
      .addCase(updatePANVerification.fulfilled, (state, actions) => {
        state.uploadPANVerificationData.data = actions.payload;
        state.uploadPANVerificationData.isLoading = false;
      })
      .addCase(updatePANVerification.rejected, (state, actions) => {
        state.uploadPANVerificationData.error = actions.error.message;
        state.uploadPANVerificationData.isLoading = false;
      })
      /* DELETE FILE DETAILS */
      .addCase(updateDeleteFile.pending, (state, actions) => {
        state.deleteFileData.isLoading = true;
      })
      .addCase(updateDeleteFile.fulfilled, (state, actions) => {
        state.deleteFileData.data = actions.payload;
        state.deleteFileData.isLoading = false;
      })
      .addCase(updateDeleteFile.rejected, (state, actions) => {
        state.deleteFileData.error = actions.error.message;
        state.deleteFileData.isLoading = false;
      })

      /* GET SCORE CARD DETAILS */
      .addCase(getScoreCardDetails.pending, (state, actions) => {
        state.scoreCardDetails.isLoading = true;
      })
      .addCase(getScoreCardDetails.fulfilled, (state, actions) => {
        state.scoreCardDetails.data = actions.payload;
        state.scoreCardDetails.isLoading = false;
      })
      .addCase(getScoreCardDetails.rejected, (state, actions) => {
        state.scoreCardDetails.error = actions.error.message;
        state.scoreCardDetails.isLoading = false;
      })
      
      .addCase(getOTPForLogin.pending, (state, actions) => {
        state.getOTPForLogin.isLoading = true;
      })
      .addCase(getOTPForLogin.fulfilled, (state, actions) => {
        state.getOTPForLogin.data = actions.payload;
        state.getOTPForLogin.isLoading = false;
      })
      .addCase(getOTPForLogin.rejected, (state, actions) => {
        state.getOTPForLogin.error = actions.error.message;
        state.getOTPForLogin.isLoading = false;
      })
      
      
      .addCase(verifyEmailToken.pending, (state, actions) => {
        state.emailTokenVerification.isLoading = true;
      })
      .addCase(verifyEmailToken.fulfilled, (state, actions) => {
        state.emailTokenVerification.data = actions.payload;
        state.emailTokenVerification.isLoading = false;
      })
      .addCase(verifyEmailToken.rejected, (state, actions) => {
        state.emailTokenVerification.error = actions.error.message;
        state.emailTokenVerification.isLoading = false;
      })
      .addCase(approveUserToken.pending, (state, actions) => {
        state.approveUser.isLoading = true;
      })
      .addCase(approveUserToken.fulfilled, (state, actions) => {
        state.approveUser.data = actions.payload;
        state.approveUser.isLoading = false;
      })
      .addCase(approveUserToken.rejected, (state, actions) => {
        state.approveUser.error = actions.error.message;
        state.approveUser.isLoading = false;
      })
      .addCase(rejectUserToken.pending, (state, actions) => {
        state.rejectUser.isLoading = true;
      })
      .addCase(rejectUserToken.fulfilled, (state, actions) => {
        state.rejectUser.data = actions.payload;
        state.rejectUser.isLoading = false;
      })
      .addCase(rejectUserToken.rejected, (state, actions) => {
        state.rejectUser.error = actions.error.message;
        state.rejectUser.isLoading = false;
      })
      
      .addCase(resetForgotPassword.pending, (state, actions) => {
        state.resetForgotPassword.isLoading = true;
      })
      .addCase(resetForgotPassword.fulfilled, (state, actions) => {
        state.resetForgotPassword.data = actions.payload;
        state.resetForgotPassword.isLoading = false;
      })
      .addCase(resetForgotPassword.rejected, (state, actions) => {
        state.resetForgotPassword.error = actions.error.message;
        state.resetForgotPassword.isLoading = false;
      })
      .addCase(postEquifaxscore.pending, (state, actions) => {
        state.postEquifax.isLoading = true;
      })
      .addCase(postEquifaxscore.fulfilled, (state, actions) => {
        state.postEquifax.data = actions.payload;
        state.postEquifax.isLoading = false;
      })
      .addCase(postEquifaxscore.rejected, (state, actions) => {
        state.postEquifax.error = actions.error.message;
        state.postEquifax.isLoading = false;
      })
      .addCase(profilePicture.pending, (state, actions) => {
        state.profilePictureData.isLoading = true;
      })
      .addCase(profilePicture.fulfilled, (state, actions) => {
        state.profilePictureData.data = actions.payload;
        state.profilePictureData.isLoading = false;
      })
      .addCase(profilePicture.rejected, (state, actions) => {
        state.profilePictureData.error = actions.error.message;
        state.profilePictureData.isLoading = false;
      })
  },
});

export const { resetGenericState, isLogout } = genericSlice.actions;

export default genericSlice.reducer;
