import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAccountReports, discountingSeller, buyerByAnchor } from '../../store/slices/lender';
import { Helmet } from "react-helmet";
import { toastMessage } from '../../layout/GenericLayout/ToastMessage';
import ExcelSheet from './Excelsheet';

const LenderLiquidation = () => {
  const [report, setReport] = useState("0");
  const [discountingSellerList, setDiscountingSellerList] = useState([]);
  const [buyerList, setBuyerList] = useState([]);
  const [tableQueryParams, setTableQueryParams] = useState({
    anchorValue: "",
    buyerValue: "",
    status: "",
  });

  const dispatch = useDispatch();
  const fetchedReports = useSelector(state => state.lender.fetchReports);
  const discountingSellerData = useSelector(state => state.lender.discountingSellerData);
  const buyerData = useSelector(state => state.lender.buyerByAnchorData);

  useEffect(() => {
    dispatch(discountingSeller());
  }, [dispatch]);

  useEffect(() => {
    if (discountingSellerData?.data) {
      setDiscountingSellerList(discountingSellerData?.data?.success);
    }
  }, [discountingSellerData]);

  // Fetch buyers when anchorValue changes
  useEffect(() => {
    if (tableQueryParams.anchorValue) {
      dispatch(buyerByAnchor(tableQueryParams.anchorValue))
        .unwrap()
        .then((response) => {
          setBuyerList(response.success || []);
        })
        .catch((error) => {
          toastMessage(error.message || "Failed to fetch buyers.", "error");
        });
    } else {
      setBuyerList([]);
    }
  }, [tableQueryParams.anchorValue, dispatch]);

  // Fetch reports when tableQueryParams changes
  useEffect(() => {
    const { anchorValue, buyerValue, status } = tableQueryParams;

    dispatch(fetchAccountReports({
      anchor: anchorValue,
      buyer: buyerValue,
      status: status,
    }))
      .unwrap()
      .then((response) => {
        toastMessage(response.message, "success");
      })
      .catch((error) => {
        toastMessage(error.message, "error");
      });
  }, [tableQueryParams, dispatch]);

  const updateQueryParams = (field, value) => {
    setTableQueryParams((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const showTable = report !== "0";

  return (
    <div className="d-flex flex-column gap-4" style={{ backgroundColor: 'white', padding: '2rem', borderRadius: '10px' }}>
      <Helmet>
        <title>Reports</title>
      </Helmet>

      <div className="d-flex justify-content-between flex-column px-2 gap-2">
        <span className="dashboard-content-title">Bill Liquidation</span>
        <label style={{ width: '100%', marginTop: '2rem', fontWeight: 'bold', fontSize: '18px' }}>Types Of Report</label>
        <div className="d-flex align-items-center py-1 px-3 col-12 col-sm-4">
          <select onChange={e => setReport(e.target.value)} value={report} className="search-input-element1 d-flex flex-grow-1">
            <option disabled value="0">Select Report Type</option>
            <option value="Statement_of_Account">Statement of Account</option>
            <option value="Overdue">Overdue</option>
          </select>
        </div>
      </div>

      {report === "Statement_of_Account" &&
        <div>
          <div className="details-container container p-3">
            <div className="row gy-md-4 gy-2">
              <div className="kyc-fields-container col-12 col-md-4">
                <label htmlFor="anchor" className="kyc-label-text form-label fw-semibold">Select the Anchor</label>
                <select
                  onChange={e => updateQueryParams('anchorValue', e.target.value)}
                  value={tableQueryParams.anchorValue}
                  className="kyc-input-field form-control"
                  style={{ backgroundColor: '#e9ecef' }}
                >
                  <option value=''>Select the Anchor</option>
                  {discountingSellerList?.map(item => (
                    <option key={item?.sellerId} value={item?.sellerId}>{item?.companyFullName}</option>
                  ))}
                </select>
              </div>

              <div className="kyc-fields-container col-12 col-md-4">
                <label htmlFor="buyer" className="kyc-label-text form-label fw-semibold">Select the Buyer</label>
                <select
                  onChange={e => updateQueryParams('buyerValue', e.target.value)}
                  value={tableQueryParams.buyerValue}
                  className="kyc-input-field form-control"
                  style={{ backgroundColor: '#e9ecef' }}
                >
                  <option value=''>Select the Buyer</option>
                  {buyerList?.map(item => (
                    <option key={item?.retailerId} value={item?.retailerId}>{item?.name}</option>
                  ))}
                </select>
              </div>

              <div className="kyc-fields-container col-12 col-md-4">
                <label htmlFor="status" className="kyc-label-text form-label fw-semibold">Select the Status</label>
                <select
                  onChange={e => updateQueryParams('status', e.target.value)}
                  value={tableQueryParams.status}
                  className="kyc-input-field form-control"
                  style={{ backgroundColor: '#e9ecef' }}
                >
                  <option value=''>Select the Status</option>
                  <option value="Liquidated">Liquidated</option>
				  <option value="Lodged">Lodged</option>
				  <option value="Disbursed">Disbursed</option>
				  <option value="Discounted">Discounted</option>
                  <option value="Partial disbursement initiated">Partial Payment Initiated</option>
                  <option value="Liquidation initiated">Liquidation Initiated</option>
                </select>
              </div>

              <div className="kyc-fields-container col-12 col-md-4" style={{ paddingTop: '15px' }}>
                <ExcelSheet tableData={fetchedReports} />
              </div>
            </div>
          </div>
        </div>
      }

      {showTable &&
        <Fragment>
          <div className="container-fluid" style={{ border: ".75px solid #efefef", borderRadius: "5px", overflowX: "auto", overflowY: "hidden" }}>
            <table className="table lender-anchor-and-lender-mapped-table1 placeholder-wave0 text-center">
              <thead>
                <tr>
                  {["CIF", "Anchor", "Invoice Amount (₹)", "Invoice Number", "Compliance check", "Buyer Confirmation", "Status"]
                    .map((element) => <th key={element} title={element} style={{ minWidth: "8rem" }}>{element}</th>)}
                </tr>
              </thead>
              <tbody>
                {fetchedReports?.data?.items?.map((element, index) => (
                  <tr key={index}>
                    <td>{element?.sellerId}</td>
                    <td>{element?.sellerName}</td>
                    <td>{element?.invoiceAmount}</td>
                    <td>{element?.invoiceId}</td>
                    <td>{element?.complianceCheck}</td>
                    <td>{element?.buyerInvoiceStatus}</td>
                    <td>{element?.invoiceStatus}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Fragment>
      }
    </div>
  );
};

export default LenderLiquidation;
