import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getInvoiceDigitalDetails,
  getAcceptInvoiceDetails,
  getInvoiceRejection,
} from "../../../store/slices/buyer";
import Loader from "../../../layout/GenericLayout/Loader";
import ReusableModal from "../../../layout/GenericLayout/ReusableModal";
import ok from "../../../assests/gif/ok.gif";
import ReusableMessageModel from "../../../layout/GenericLayout/ReusableMessageModel";
import { toast } from "react-toastify";

const OkResponse = (props) => {
  // console.log("I have PDF LINK cross Verifing: ", props.pdfLink)
  return (
    <div className="mt-5" style={{ maxWidth: "100%" }}>
      <p>{props.message}</p>
    </div>
  );
};

  /**********************************************
   * This is Model style for Reject Reason form
   **********************************************/

  const RejectMsgModel = (props)=>{

    const dispatch = useDispatch();
    const navigate=useNavigate();
    const [rejectionRemarks, setRejectionRemarks] = useState();
    const handleOnChange = (event)=>{
      event.preventDefault();
      setRejectionRemarks(event.target.value);
    }

    // console.log("asdfadfsdfsdfdsf____", rejectionRemarks);
    
    const finalRejectInvoiceSubmit = ()=>{

      if(!rejectionRemarks){
        toast.error("Please enter rejection remarks");
        return;
      }
      dispatch(
        getInvoiceRejection({
          'acceptanceCriteria': false,
          'invoiceId': props.invoiceNumber,
          'rejectionRemarks': rejectionRemarks,
          'sellerId': props.sellerID,
          // sellerId: location.state,
          'status': "Rejected",
        })
      )
      .then((data) => {
        // Handle success scenario here
        toast.success(data?.payload?.success);
        navigate("/buyer/invoice");
        // console.log("Invoice rejection submitted successfully.",data?.payload?.success);
      })
      .catch((error) => {
        // Handle error scenario here
        toast.error(error?.error?.message)
        console.error("Error submitting invoice rejection:", error);
      });
    }
    return(
      <div className="mt-5 d-flex flex-column justify-content-center align-items-center" style={{ maxWidth: "100%" }}>
      <p>{props.message}</p>
      <input type="text" onChange={handleOnChange} 
      
      // onSubmit={finalRejectInvoiceSubmit}
      />
      <div className="d-flex">
        <button 
        className="mt-3 mx-3 btn btn-danger rounded" 
        type="button"
        onClick={props.toggleModal}
        >Cancel</button>

      <button 
        className="mt-3 btn btn-primary rounded" 
        type="button"
        onClick={finalRejectInvoiceSubmit}
        >Submit</button>

      </div>
    </div>
    )
  }

const BuyerInvoiceDigitalDetails = () => {
  const { invoiceNumber } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isTermsAndConditionsAccepted, setIsTermsAndConditionsAccepted] =
    useState(false);
  const buyerInvoiceDetailsResponse = useSelector(
    (state) => state.buyer.InvoiceDigitalDetails
  );
  const AcceptInvoiceDetailsResponse = useSelector(
    (state) => state.buyer.AcceptInvoiceDetails
  );
  const rejectInvoiceDetailsResponse = useSelector(
    (state) => state.buyer.invoiceRejection
  );
  const [buyerInvoiceDetails, setBuyerInvoiceDetails] = useState();
  const [showAcceptModalStatus, setShowAcceptModalStatus] = useState(false);
  const [showRejectModalStatus, setShowRejectModalStatus] = useState(false);


  const toggleAcceptModal = () => {
    setShowAcceptModalStatus(!showAcceptModalStatus);
    // navigate("/buyer/invoice");
  };

  const toggleRejectModal = () => {
    setShowRejectModalStatus(!showRejectModalStatus);
  };

  const navigateBackToInvoice = () => {
    navigate("/buyer/invoice");
  };

  useEffect(() => {
    dispatch(
      getInvoiceDigitalDetails({
        sellerID: location.state,
        invoiceNumber: invoiceNumber,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (buyerInvoiceDetailsResponse.data) {
      setBuyerInvoiceDetails(buyerInvoiceDetailsResponse.data?.items[0]);
    }
  }, [buyerInvoiceDetailsResponse.data]);

  // console.log(buyerInvoiceDetails);

  // handle checkbox check and uncheck
  const handleOnChangeTermsAndConditionAccept = (event) => {
    setIsTermsAndConditionsAccepted(!isTermsAndConditionsAccepted);
  };

  // <iframe src="https://giphy.com/embed/YUWtZ4KiMqzmuekUb1" width="480" height="480" frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/stickers/BigPotatoGames-transparent-linkee-YUWtZ4KiMqzmuekUb1">via GIPHY</a></p>
  // console.log(isTermsAndConditionsAccepted);

  const handleAcceptInvoiceDetails = () => {
    let bodyToSend = {
      sellerId: location.state,
      invoiceId: invoiceNumber,
      status: "Accepted",
      acceptanceCriteria: true,
    };
    dispatch(getAcceptInvoiceDetails(bodyToSend));
  };

  useEffect(() => {
    if (AcceptInvoiceDetailsResponse.data) {
      setShowAcceptModalStatus(true);
    }
  }, [AcceptInvoiceDetailsResponse.data]);

  const handleReject = () => {
    setShowRejectModalStatus(true);
  };



  console.log(
    "<== Inside Invoice number Details: For Accept and Reject ==> ",
    showAcceptModalStatus
  );






  return (
    <>
      {showAcceptModalStatus && (
        <ReusableMessageModel
          showModal={showAcceptModalStatus}
          toggleModal={navigateBackToInvoice}
          msgType={"ok"}
          modalContent={
            <OkResponse 
            message={"invoice Accepted Successfully"} 
            toggleModal={toggleAcceptModal}
            />
          }
          modalSizeClass="modal-lg"
        />
      )}
      {showRejectModalStatus && (
        <ReusableMessageModel
          showModal={showRejectModalStatus}
          toggleModal={toggleRejectModal}
          // msgType={"ok"}
          noBtn = "true"
          modalContent={
            <RejectMsgModel 
            message={"Rejection Reason"} 
            invoiceNumber={invoiceNumber}
            sellerID={location.state}
            toggleModal={toggleRejectModal}
            />
          }
          modalSizeClass="modal-lg"
        />
      )}
      
      <div className="d-flex flex-column m-0 p-0 gap-4 request-details-cont mb-3">
        <div className="container-with-heading d-flex flex-column container">
          <span className="content-title mb-3 row">Invoice Details</span>
          <div className="details-container ">
            <div className="fields-container container-with-heading  px-0 row ">
              <span className="content-title  ">Items Information</span>
              <table className="w-100 col-12 m-0 ">
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Model Number</th>
                    <th>Quantity Ordered</th>
                    <th>Available Quantity</th>
                    <th>Price/Unit</th>
                    <th>Tax(%)</th>
                    <th>Total Amount</th>
                  </tr>
                </thead>
                {buyerInvoiceDetailsResponse.isLoading ? (
                  <tbody>
                    <tr>
                      <td colSpan={7}>
                        <Loader className="my-5" />
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td>{buyerInvoiceDetails?.itemDetails[0]["item"]}</td>
                      <td>
                        {buyerInvoiceDetails?.itemDetails[0]["modelNumber"]}
                      </td>
                      <td>
                        {buyerInvoiceDetails?.itemDetails[0]["quantityOrdered"]}
                      </td>
                      <td>
                        {
                          buyerInvoiceDetails?.itemDetails[0][
                            "quantityAvailable"
                          ]
                        }
                      </td>
                      <td>
                        {buyerInvoiceDetails?.itemDetails[0]["unitPrice"]}
                      </td>
                      <td>{`${buyerInvoiceDetails?.itemDetails[0]["tax"]} %`}</td>
                      <td>{`₹ ${buyerInvoiceDetails?.itemDetails[0][
                        "totalAmount"
                      ].toFixed(2)}`}</td>
                    </tr>
                    <tr>
                      <td colSpan={6}>{"Total"}</td>
                      <td>{`₹ ${buyerInvoiceDetails?.itemDetails[0][
                        "totalAmount"
                      ].toFixed(2)}`}</td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>

            {/* TransPort Details */}

            <div className="fields-container container-with-heading px-0 row mt-3 pt-0 gy-3">
              <span className="content-title mt-0">Invoice Address</span>
              <div className="col-12  col-md-4">
                <label
                  htmlFor="DeliveryLocation"
                  className="label-text form-label"
                >
                  Shipment Terms
                </label>
                <input
                  id="DeliveryLocation"
                  name="DeliveryLocation"
                  type="text"
                  disabled
                  value={buyerInvoiceDetails?.shippingTerms}
                  className={`input-field form-control`}
                />
                <span className="text-danger px-1"></span>
              </div>

              <div className="fields-container col-12  col-md-4">
                <label htmlFor="RequiredDate" className="label-text form-label">
                  Mode of Transport
                </label>
                <input
                  id="RequiredDate"
                  type="text"
                  name="RequiredDate"
                  disabled
                  value={buyerInvoiceDetails?.modeOfTransport}
                  className={`input-field form-control`}
                />

                <span className="text-danger px-1"></span>
              </div>

              <div className="fields-container col-12  col-md-4">
                <label htmlFor="Shipment" className="label-text form-label">
                  Expected Date of delivery
                </label>
                <input
                  id="Shipment"
                  type="text"
                  name="Shipment"
                  className={`input-field form-control`}
                  disabled
                  value={buyerInvoiceDetails?.expectedDateOfDelivery}
                />
                <span className="text-danger px-1"></span>
              </div>
              <div className="fields-container col-12  col-md-4">
                <label htmlFor="Shipment" className="label-text form-label">
                  Delivery Location
                </label>
                <input
                  id="Shipment"
                  type="text"
                  name="Shipment"
                  className={`input-field form-control`}
                  disabled
                  value={buyerInvoiceDetails?.locationOfDelivery}
                />
                <span className="text-danger px-1"></span>
              </div>

              <div className="fields-container col-12  col-md-4">
                <label htmlFor="Shipment" className="label-text form-label">
                  Payment Terms
                </label>
                <input
                  id="Shipment"
                  type="text"
                  name="Shipment"
                  className={`input-field form-control`}
                  disabled
                  value={buyerInvoiceDetails?.paymentTerms}
                />
                <span className="text-danger px-1"></span>
              </div>

              <div className="fields-container col-12  col-md-4">
                <label htmlFor="Shipment" className="label-text form-label">
                  Additional Text
                </label>
                <input
                  id="Shipment"
                  type="text"
                  name="Shipment"
                  className={`input-field form-control`}
                  disabled
                  // The API IS INKNOWN
                  value={buyerInvoiceDetails?.additionalText}
                />
                <span className="text-danger px-1"></span>
              </div>
            </div>

            {/* </div> */}

            <div className="fields-container container-with-heading px-0 row mt-3 gy-3">
              <span className="content-title mt-0">Terms and Condition</span>
              <div className="col-12">
                <ul>
                  <li>{`Good Received against invoice ${invoiceNumber} are in good condition and quantities are as per mentioned in invoice.`}</li>
                  <li>Captioned invoice is fit to finance.</li>
                  <li>
                    I indemnify lender and the platform for any losses arising
                    out of this transaction.
                  </li>
                  <li>
                    I ensure timely repayment of the loan amount as per schedule
                    date communicated by the financial institution & platform.
                  </li>
                </ul>
                <div className="mx-2 mb-2">
                  <input
                    className="mx-1"
                    type="checkbox"
                    id="acceptTermsConditions"
                    checked={isTermsAndConditionsAccepted}
                    onChange={handleOnChangeTermsAndConditionAccept}
                  />
                  <label
                    className="buyer-prevent-select"
                    htmlFor="acceptTermsConditions"
                  >
                    {" "}
                    {`Accept`}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end my-2 px-4 container mb-5">
            <button
              className="back-button"
              onClick={() => {
                navigate("/buyer/invoice");
              }}
            >
              Back
            </button>
            <button
              className="danger-button"
              onClick={() => {
                // navigate("/buyer/invoice");
                handleReject();
              }}
            >
              Reject
            </button>
            {isTermsAndConditionsAccepted ? (
              <button
                className="success-button"
                onClick={handleAcceptInvoiceDetails}
              >
                Accept
              </button>
            ) : (
              <button
                className="success-button-disabled"
                // onClick={handleAcceptInvoiceDetails}
                disabled
              >
                Accept
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyerInvoiceDigitalDetails;
