import { Fragment, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toastMessage } from "../../layout/GenericLayout/ToastMessage";
import { useDispatch } from "react-redux";
import { approveUserToken } from "../../store/slices/generic";

export const ApproveUser = () => {

	const { token } = useParams();
	const dispatch = useDispatch();

	const navigate = useNavigate();

	useEffect(() => {
		if (!token) {
			toastMessage("Token Not Found", "error");
			
			setTimeout(() => navigate("/login", { replace: true, }), 2000);
		}

		else {
			dispatch(approveUserToken(token))
				.unwrap()
				.then(data => {
					toastMessage((data?.message ?? "Verified successfully") + " Please Sign In to Continue", "success");
					setTimeout(() => navigate("/login", { replace: true, }), 3000);
				})
				.catch(err => {
					toastMessage(err?.message, "error");
					setTimeout(() => navigate("/login", { replace: true, }), 3000);
				})
		}
		
	}, [token, dispatch, navigate])

	return (
		<Fragment></Fragment>
	);
};
