import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { BsSearch, BsDownload } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toastMessage } from "../../layout/GenericLayout/ToastMessage";
import repaymentSample from "../../assests/sampleData/repayment_sample.xlsx"
import * as XLSX from "xlsx"; // Import xlsx library
import {
  getBillLodging, getBillLodgingExcel, uploadLenderRepayment
} from "../../store/slices/lender";
import "../../style/components/extra.css";
import LenderInvoiceTable from "../Lender/LenderTables/LenderBillLogdingTable";

const LenderBillLogding = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Redux selectors for table data
  const billData = useSelector((state) => state.lender.billLoding);

  const [activeTab, setActiveTab] = useState(1); // Active tab state
  const [tableQueryParams, setTableQueryParams] = useState({
    tableOffset: 0,
    tableLimit: 5,
    tableSort: "proformaId",
    tableOrder: 1,
    currentPage: 1,
    search: "",
    status: "New", // Default status
  });

  const [searchByInvoiceID, setSearchByInvoiceID] = useState("");

  // Fetch data based on active tab
  useEffect(() => {
    dispatch(getBillLodging(tableQueryParams));
  }, [tableQueryParams, dispatch]);

  // Handle tab switch
  const handleTabSwitch = (tabIndex, status) => {
    setActiveTab(tabIndex);
    setTableQueryParams((prev) => ({
      ...prev,
      status,
      tableOffset: 0,
      search: "",
    }));
    setSearchByInvoiceID("");
  };

  const exportToExcelFile = async (status) => {
    try {
      const response = await dispatch(getBillLodgingExcel(status));

      if (response && response.payload) {
        const blob = new Blob([response.payload]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.click();
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  function base64StringToBlob(base64String) {
    // Validate and preprocess the Base64 string
    if (!base64String || typeof base64String !== 'string') {
      throw new Error("Invalid Base64 string");
    }

    // Replace URL-safe Base64 characters
    const base64 = base64String.replace(/-/g, '+').replace(/_/g, '/');

    // Add padding if missing
    const paddedBase64 = base64.padEnd(base64.length + (4 - (base64.length % 4)) % 4, '=');

    try {
      const byteCharacters = atob(paddedBase64);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      // Specify the MIME type
      return new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    } catch (error) {
      console.error("Error decoding Base64 string:", error);
      throw new Error("Failed to decode Base64 string");
    }
  }


  function downloadBase64File(blob, fileName) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;

    document.body.appendChild(a);
    a.click();

    // Clean up
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  const onClickDownloadRec = (status) => {
    dispatch(getBillLodgingExcel(status))
      .unwrap()
      .then((response) => {
        const excelFile = response.file_data;
        const fileName = `Bill Lodging Report.xlsx`;

        if (excelFile) {
          const blob = base64StringToBlob(excelFile);
          downloadBase64File(blob, fileName);
        }
      })
      .catch((error) => {
        console.error("Error downloading the file:", error);
      });
  };



  const uploadRepaymentData = (event) => {
    const file = event.target.files[0];
    dispatch(uploadLenderRepayment(file))
      .unwrap()
      .then((response) => {
        toastMessage(response.success, "success")
      })
      .catch((error) => {
        console.log("error : ", error);
        toastMessage(error.message, "error");
      });
  };

  const RedirectFunct = (data, data1) => {
    sessionStorage.setItem("CustomerId", data);
    sessionStorage.setItem("InvoiceId", data1);
    navigate("/lender/bill-lodging/invoice-details", { state: { data } });
  };

  const handleBillDetail = (data) => {
    sessionStorage.setItem("ReferenceBillId", data);
    navigate({ pathname: "/lender/bill-lodging/bill-deails" });
  };

  const handleUnregister = (data, data1, data2) => {
    //   console.log("invoiceId",data,"SellerId",data1,"manualInvoice",data2);
    sessionStorage.setItem("InvoiceId", data);
    sessionStorage.setItem("SellerId", data1);
    sessionStorage.setItem("digitalInvoice", data2);
    navigate({ pathname: "/lender/bill-lodging/unregistered_bill" });
  };


  // Handle search
  const handleSearchData = () => {
    setTableQueryParams((prev) => ({
      ...prev,
      search: searchByInvoiceID,
      tableOffset: 0,
    }));
  };

  const exportToExcel = () => {
    const tableRows = billData.data?.items || [];
    if (tableRows.length === 0) {
      toastMessage("No data available for export.", "info");
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(tableRows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Bills");
    XLSX.writeFile(workbook, "Bill_Lodgement_Data.xlsx");
    toastMessage("Excel file downloaded successfully.", "success");
  };

  const handleSearchKeyPress = (e) => {

    handleSearchData();

  };

  return (
    <div className="d-flex flex-column gap-4">
      <Helmet>
        <title>Bill Lodgement</title>
      </Helmet>
      {/* Header Section */}
      <div className="bg-white admin-dashboard-card-container p-3">
        <div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2 py-2">
          <div style={{ flex: 6, }}>
            <div className="dashboard-content-title">List of Bills</div>
            <div className="d-flex flex-column flex-sm-row align-items-center my-4">
              <div className="d-flex flex-column justify-content-center gap-3 col-12 col-sm-10">
                <div className="search-container d-flex align-items-center py-1 px-3 col-12 col-sm-8">
                  <input
                    className="search-input-element flex-grow-1"
                    type="text"
                    placeholder="Search by Invoice Number"
                    value={searchByInvoiceID}
                    onChange={(e) => setSearchByInvoiceID(e.target.value)}
                    onKeyDown={handleSearchKeyPress}
                  />
                  <BsSearch className="fs-5" />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="d-flex align-items-center row" style={{ flex: 4, }}>
            <div className="col-12 m-0 p-0" style={{ display: "inline-block", textAlign: "center" }}>
              <label htmlFor="upload_invoice" className="form-label text-primary" style={{ cursor: "pointer", }}>Upload Disbursed Invoices (.csv,.xls,.xlsx)</label>
              <input type="file" style={{ display: "none" }} onChange={uploadRepaymentData} id="upload_invoice" accept=".csv,.xls,.xlsx" />
            </div>
            <div className="m-0 p-0">
              <a href={repaymentSample}>
                <button type="button" className="btn btn-outline-danger">

                  Download Disbursed Invoice Sample (.csv, .xls, .xlsx) &nbsp;&nbsp;&nbsp;
                  <BsDownload style={{ fontWeight: "600", fontSize: "2rem" }} />
                </button>
              </a>
            </div>
          </div> */}
        </div>


      </div>

      {/* Header Section */}


      {/* Tab Navigation */}
      <div className="d-flex flex-column justify-content-between flex-wrap px-2">
        <div className="d-flex m-0 p-0">
          {[
            { name: "New", status: "New", id: 1 },
            { name: "Funded", status: "Funded", id: 2 },
            { name: "Repaid", status: "Repaid", id: 3 },
            { name: "Rejected", status: "Rejected", id: 4 },
          ].map((tab) => (
            <span
              key={tab.id}
              className={`dashboard-table-title py-2 px-3 ${activeTab === tab.id
                ? "active-table-data"
                : "dashboard-table-title-hover"
                }`}
              onClick={() => handleTabSwitch(tab.id, tab.status)}
            >
              {tab.name}
            </span>
          ))}
          <div className="m-0 p-0">
            <button
              type="button"
              className="btn btn-success ms-3"
              onClick={() => onClickDownloadRec(tableQueryParams.status)}
            >
              Export Table to Excel
            </button>
          </div>
        </div>

        {/* Table Section */}

        <LenderInvoiceTable
          className="lender-anchor-and-lender-mapped-table1 placeholder-wave0"
          tableHeader={[
            { name: "CIF", sortValue: "addedDate" },
            { name: "Anchor", sortValue: "proformaId" },
            { name: "Counter Party", sortValue: "" },
            { name: "Invoice Number", sortValue: "expectedDateOfDelivery" },
            { name: "Invoice Amount", sortValue: "sellerName" },
            { name: "Compliance Check", sortValue: "paymentTerms" },
            {
              name: "Counter Party Confirmation",
              sortValue: "proformaStatus",
            },
            { name: "Bill Reference Number", sortValue: "proformaStatus" },
            { name: "Status", sortValue: "proformaStatus" },
          ]}
          tableRows={billData.data?.items || []}
          materialRequestMetaData={billData.data?.metadata || null}
          tableQueryParams={tableQueryParams}
          setTableQueryParams={setTableQueryParams}
          isLoading={billData.isLoading}
          RedirectFunct={RedirectFunct}
          handleBillDetail={handleBillDetail}
          handleUnregister={handleUnregister}
        />
      </div>
    </div>
  );
};

export default LenderBillLogding;
