import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../utilities/api";
import axios from "axios";
import { DOC_URL } from "../../utilities/config";

const initialState = {
  /* FROM ADMIN DAHSBOARD */
  corporateOnboardData: {
    data: null,
    isLoading: false,
    error: null,
  },
  billLoding: {
    data: null,
    isLoading: false,
    error: null,
  },
  billLodgingDigital: {
    data: null,
    isLoading: false,
    error: null,
  },
  billLodgingManual: {
    data: null,
    isLoading: false,
    error: null,
  },
  billLoding1: {
    data: null,
    isLoading: false,
    error: null,
  },
  billLoding2: {
    data: null,
    isLoading: false,
    error: null,
  },
  billLoding3: {
    data: null,
    isLoading: false,
    error: null,
  },

  billLodingExcel: {
    data: null,
    isLoading: false,
    error: null,
  },
  invoicedata: {
    data: null,
    isLoading: false,
    error: null,
  },
  Transacdata: {
    data: null,
    isLoading: false,
    error: null,
  },
  PurchaseData: {
    data: null,
    isLoading: false,
    error: null,
  },
  billData: {
    data: null,
    isLoading: false,
    error: null,
  },
  disburshedData: {
    data: null,
    isLoading: false,
    error: null,
  },

  // This is in Lender DashBoard
  LiquidDationDueData: {
    data: null,
    isLoading: false,
    error: null,
  },

  // This Business GRowth Contributors
  BusinessGrowthContributorsData: {
    data: null,
    isLOading: false,
    error: null,
  },
  underWriter: {
    data: null,
    isLoading: false,
    error: null,
  },
  agreementList: {
    data: null,
    isLoading: false,
    error: null,
  },
  approveLimit: {
    data: null,
    isLoading: false,
    error: null,
  },
  createAgreement: {
    data: null,
    isLoading: false,
    error: null,
  },
  uploadAgreement: {
    data: null,
    isLoading: false,
    error: null,
  },
  viewAgreement: {
    data: null,
    isLoading: false,
    error: null,
  },
  sendAgreement: {
    data: null,
    isLoading: false,
    error: null,
  },
  modifyApi: {
    data: null,
    isLoading: false,
    error: null,
  },
  deleteApi: {
    data: null,
    isLoading: false,
    error: null,
  },
  unregisteredData: {
    data: null,
    isLoading: false,
    error: null,
  },
  acceptpo: {
    data: null,
    isLoading: false,
    error: null,
  },
  dropDown: {
    data: null,
    isLoading: false,
    error: null,
  },
  createApi: {
    data: null,
    isLoading: false,
    error: null,
  },
  addUnderWriter: {
    data: null,
    isLoading: false,
    error: null,
  },
  lenderPostDoc: {
    data: null,
    isLoading: false,
    error: null,
  },
  kycdataUpdate: {
    data: null,
    isLoading: false,
    error: null,
  },
  kycData: {
    data: null,
    isLoading: false,
    error: null,
  },
  ifscVerify: {
    data: null,
    isLoading: false,
    error: null,
  },
  discountData: {
    data: null,
    isLoading: false,
    error: null,
  },
  updateCreditLimitStatusData: {
    data: null,
    isLoading: false,
    error: null,
  },

  getLenderDisbursedInvoiceTableData: {
    data: null,
    isLoading: false,
    error: null,
  },

  uploadLenderDisbursedInvoiceData: {
    data: null,
    isLoading: false,
    error: null,
  },

  uploadLenderRepaymentData: {
    data: null,
    isLoading: false,
    error: null,
  },

  repaidData: {
    data: null,
    isLoading: false,
    error: null,
  },

  getLenderManualInoviceClickData: {
    data: null,
    isLoading: false,
    error: null,
  },
  unregisteredBillOfExchange: {
    data: null,
    isLoading: false,
    error: null,
  },
  unregisteredLorryReceipt: {
    data: null,
    isLoading: false,
    error: null,
  },
  fetchCounterPartyUsingAnchorId: {
    data: null,
    isLoading: false,
    error: null,
  },
  fetchReports: {
    data: null,
    error: null,
    isLoading: false,
  },
  dashboardData: {
    data: null,
    error: null,
    isLoading: false,
  },
  invoiceStatus: {
    data: null,
    isLoading: false,
    error: null,
  },
  discountingSellerData: {
    data: null,
    isLoading: false,
    error: null,
  },
  buyerByAnchorData: {
    data: null,
    isLoading: false,
    error: null,
  },
};

/* FROM LENDER DASHBOARD */
export const getDashboard = createAsyncThunk(
  "get/dashboardData",
  async (reportType = "") => {
    try {

      const url = `/analytics/banker/banker_dashboard`;

      /**
       * @type { import("axios").AxiosRequestHeaders }
       */
      const headers = { Authorization: "Bearer " + sessionStorage.getItem("token"), };

      const { data } = await api.get(url, { headers });

      return data;
    }
    catch (err) {
      throw new Error(err?.response?.data?.error);
    }
  }
);

export const fetchAccountReports = createAsyncThunk(
  "get/reports",
  async (body) => {
    try {
      const { anchor, buyer, status } = body;

      // Construct query parameters dynamically
      const queryParams = [];
      if (anchor) queryParams.push(`customer_id=${anchor}`);
      if (buyer) queryParams.push(`buyer_id=${buyer}`);
      if (status) queryParams.push(`field=${status}`);

      // Create the final URL
      const url = queryParams.length > 0
        ? `/report/get_report?${queryParams.join("&")}`
        : `/report/get_report?`;

      /**
       * @type { import("axios").AxiosRequestHeaders }
       */
      const headers = { Authorization: "Bearer " + sessionStorage.getItem("token") };

      const { data } = await api.get(url, { headers });

      return data;
    } catch (err) {
      throw new Error(err?.response?.data?.error);
    }
  }
);

export const discountingSeller = createAsyncThunk(
  "get/discountingSeller",
  async (reportType = "") => {
    try {

      const url = `/user/dsicounting_selller`;

      /**
       * @type { import("axios").AxiosRequestHeaders }
       */
      const headers = { Authorization: "Bearer " + sessionStorage.getItem("token"), };

      const { data } = await api.get(url, { headers });

      return data;
    }
    catch (err) {
      throw new Error(err?.response?.data?.error);
    }
  }
);


export const buyerByAnchor = createAsyncThunk(
  "get/buyerByAnchor",
  async (anchorId, { rejectWithValue }) => {
    try {
      const url = `/user/get_all_retailers/${anchorId}`;

      /**
       * @type { import("axios").AxiosRequestHeaders }
       */
      const headers = {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      };

      if (!headers.Authorization) {
        throw new Error("Authorization token is missing.");
      }

      const response = await api.get(url, { headers });
      return response.data; // Returning only the relevant data payload
    } catch (err) {
      const errorMessage =
        err?.response?.data?.error || "An unexpected error occurred.";
      console.error("Error in buyerByAnchor:", errorMessage);
      return rejectWithValue(errorMessage); // Propagate error to Redux
    }
  }
);


export const fetchCounterPartyUsingAnchorId = createAsyncThunk(
  "get/counterPartyForAnchor",
  async (anchorId) => {
    try {
      const url = "user/get_retailer_for_lender/" + anchorId;

      const headers = {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      };

      const { data } = await api.get(url, { headers });

      return data;
    } catch (error) {
      throw new Error(error?.response?.data?.message);
    }
  }
);

export const getIfscVerified = createAsyncThunk(
  "get/ifscVerify",
  async (data) => {
    try {
      const url = "banker/kyc/ifsc/" + data;
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const postDeletApi = createAsyncThunk(
  "delete/deleteApi",
  async (data) => {
    try {
      const url = "/banker/invoiceDiscount/transactions";
      const response = await api.delete(url, {
        data: data,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const postModifyData = createAsyncThunk(
  "put/modifyApi",
  async (data) => {
    try {
      const url = "/banker/invoiceDiscount/transaction";
      const response = await api.put(url, data, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const postDiscount = createAsyncThunk(
  "post/discountData",
  async (data) => {
    try {
      const url = "/banker/invoiceDiscount";
      const response = await api.post(url, data, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const postAddUnderWriter = createAsyncThunk(
  "post/addUnderWriter",
  async (data) => {
    try {
      const url = "/underwriter/uw_register";
      const response = await api.post(url, data, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const postCreateData = createAsyncThunk(
  "post/createApi",
  async (data) => {
    try {
      const url = "/banker/invoiceDiscount/transaction";
      const response = await api.post(url, data, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const postKycData = createAsyncThunk(
  "post/kycdataUpdate",
  async (data) => {
    try {
      const url = "/banker/kyc";
      const response = await api.post(url, data, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const postUploadDoc = createAsyncThunk(
  "post/lenderPostDoc",
  async (data) => {
    try {
      const url = `${DOC_URL}kyc/docs`;
      const response = await api.post(url, data, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const postSendAgreement = createAsyncThunk(
  "post/sendAgreement",
  async (data) => {
    try {
      const url = "user/esignrequest";
      const response = await api.post(url, data, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const postAccept = createAsyncThunk("post/acceptpo", async (data) => {
  try {
    const url = "banker/invoiceDiscount/lodgement";
    const response = await api.post(url, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
});

export const postViewAgreement = createAsyncThunk(
  "put/viewAgreement",
  async (data) => {
    try {
      const url = "user/view_agreements";
      const response = await api.put(url, data, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const postUploadAgreement = createAsyncThunk(
  "post/uploadAgreement",
  async (data) => {
    try {
      const url = "user/upload_agreement";
      const response = await api.post(url, data, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const postCreateAgreement = createAsyncThunk(
  "post/createAgreement",
  async (data) => {
    try {
      const url = "user/create_agreement";
      const response = await api.post(url, data, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const getApproveLimit = createAsyncThunk(
  "get/approveLimit",
  async () => {
    try {
      const url =
        "user/get_dist_for_lender/" + sessionStorage.getItem("customerId");
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getUserKYCDocument = createAsyncThunk("get/kycData", async () => {
  try {
    const url = "/user/kyc";
    const response = await api.get(url, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const getAgreementList = createAsyncThunk(
  "get/agreementList",
  async () => {
    try {
      const url = "user/getuser/" + sessionStorage.getItem("customerId");
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getUnderWriter = createAsyncThunk("get/underWriter", async (obj) => {
  try {
    const url = `user/list_of_underwriters/${sessionStorage.getItem("customerId")}?offset=${obj.tableOffset}&limit=${obj.tableLimit}&search=${obj.search}`;
    console.log(url, "url");
    const response = await api.get(url, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const getRiskManagementData = createAsyncThunk(
  "get/corporateOnboard",
  async () => {
    try {
      const url = "user/getuser/" + sessionStorage.getItem("customerId");
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getBillLodging = createAsyncThunk(
  "get/billLoding",
  async (data) => {
    console.log("limit : ", data);
    try {
      const url =
        `banker/invoiceDiscounting?status=${data.status}&offset=${data.tableOffset}&limit=${data.tableLimit}&search=${data.search}`;
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getRepaid = createAsyncThunk(
  "get/repaidData",
  async (data) => {
    console.log("limit : ", data);
    try {
      const url =
        `banker/invoiceDiscounting?status=Repaid&offset=${data.tableOffset}&limit=${data.tableLimit}&search=${""}`;
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getBillLodgingExcel = createAsyncThunk(
  "get/billLodingExcel",
  async (status) => {
    console.log("limit : ", status);
    try {
      const url =
        `/banker/export_invoices?status=${status}`;
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getSearchApi = createAsyncThunk(
  "get/billLoding2",
  async (data, data1) => {
    console.log(data);
    try {
      const url =
        "banker/invoiceDiscount?offset=" +
        0 +
        "&limit=10&manual_invoice=" +
        false +
        "&search=" +
        data;
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getSearchApi1 = createAsyncThunk(
  "get/billLoding3",
  async (data) => {
    try {
      const url =
        "banker/invoiceDiscount?offset=" +
        0 +
        "&limit=10&manual_invoice=" +
        true +
        "&search=" +
        data;
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getBillLodging1 = createAsyncThunk(
  "get/billLoding1",
  async (data) => {
    console.log("data : ", data);
    try {
      const url =
        `banker/invoiceDiscount?offset=${data.tableOffset}&limit=${data.tableLimit}&manual_invoice=true&search=${data.search}`;
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const getBillLodgingDigital = createAsyncThunk(
  "get/getBillLodgingDigital",
  async (data) => {
    try {
      const url = `banker/invoiceDiscount?${data}&manual_invoice=false`;
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getBillLodgingManual = createAsyncThunk(
  "get/banker/invoiceDiscount?offset=0&limit=10&manual_invoice=true",
  async (data) => {
    try {
      const url = `banker/invoiceDiscount?${data}&manual_invoice=true`;
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getataInvoiceD = createAsyncThunk(
  "get/invoicedata",
  async ({ sellerId }) => {
    try {
      console.log("invoice id", sessionStorage.getItem("InvoiceId"));

      const url =
        "banker/invoice/" +
        sellerId +
        "/" +
        sessionStorage.getItem("InvoiceId");
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getTransactionData = createAsyncThunk(
  "get/Transacdata",
  async (data) => {
    try {
      const url = "banker/invoiceDiscount/transaction";
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getPurchaseOrder = createAsyncThunk(
  "get/PurchaseData",
  async (data) => {
    try {
      let url = "seller/manualpo?offset=" + data.tableQueryParams.tableOffset + "&limit=" + data.tableQueryParams.tableLimit + "&search=" + data.search;
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getDropDownData = createAsyncThunk(
  "get/dropDown",
  async (data) => {
    try {
      const url =
        "user/get_distributors_list_for_lenders/" +
        sessionStorage.getItem("customerId");
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const getInvoiceStatus = createAsyncThunk(
  "get/invoiceStatus",
  async (body) => {
    try {
      const url =
        "banker/invoice_status"
      const response = await api.post(url, body, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const postDisbursement = createAsyncThunk(
  "post/disbursement",
  async (body) => {
    try {
      const url =
        "banker/disbursement"
      const response = await api.post(url, body, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

/**
 * This Is Lender DashBoard
 */

export const getLiquidDationDue = createAsyncThunk(
  "get/liquadation_due",
  async () => {
    try {
      const url = "analytics/banker/liquadation_due";
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getBillDetail = createAsyncThunk("get/billData", async (data) => {
  try {
    const url =
      "banker/invoiceDiscount/" + sessionStorage.getItem("ReferenceBillId");
    const response = await api.get(url, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const getUnregisteredBillDetail = createAsyncThunk(
  "get/unregisteredData",
  async (data) => {

    //check manual or digital
    let manual = "true";
    if (sessionStorage.getItem("digitalInvoice") === "true") {
      manual = "false";
    }

    try {
      const url =
        "banker/invoiceDiscount/" +
        sessionStorage.getItem("InvoiceId") +
        "?sellerId=" +
        sessionStorage.getItem("SellerId") +
        "&manualInvoice=" + manual;
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getDisbursedData = createAsyncThunk(
  "get/disburshedData",
  async (data) => {
    try {
      const url = `banker/invoiceDiscount/${data}`;
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getBusinessGrowthContributors = createAsyncThunk(
  "get/getTopCustomer",
  async () => {
    try {
      const url = "analytics/banker/top_customers";
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const updateCreditLimitStatus = createAsyncThunk(
  "put/updateCreditLimitStatus",
  async (requestObj) => {
    try {
      const url = "banker/verifymaxlimit";
      const { data } = await api.put(url, requestObj, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      });

      return data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getLenderDisbursedInvoiceTable = createAsyncThunk(
  "get/lenderDisbursedInvoiceTable",
  async (obj) => {
    try {
      const url = `banker/disbursedInvoices?manual_invoice=true&offset=${obj?.tableOffset}&limit=${obj?.tableLimit}&search=${obj?.searchValue}`;
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const uploadLenderDisbursedInvoice = createAsyncThunk(
  "post/lenderDisbursedInvoice",
  async (formData) => {
    try {
      const url = `banker/upload_disbursed_excel_data`;
      const response = await api.post(url, formData, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const uploadLenderRepayment = createAsyncThunk(
  "post/lenderRepayment",
  async (formData) => {
    try {
      const url = `banker/upload_repayment_excel_data`;
      const response = await api.post(url, formData, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getLenderManualInoviceClick = createAsyncThunk(
  "get/lenderManualInoviceClic",
  async ({ invoiceId, sellerId }) => {
    try {
      const url = `banker/invoice/manual/${invoiceId}?sellerId=${sellerId}`;
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getUnRegisteredBillOfExchange = createAsyncThunk(
  "get/Unregistered/billOfExchange",
  async ({ invoiceId, sellerId }) => {
    try {
      // sessionStorage.getItem("InvoiceId") +
      // "?sellerId=" +
      // sessionStorage.getItem("SellerId") +
      // "&manualInvoice=false";
      const url = `${DOC_URL}swarm/docs/${sessionStorage.getItem(
        "SellerId"
      )}/${sessionStorage.getItem("InvoiceId")}/false/billOfExchange`;
      const response = await axios.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getUnRegisteredLorryReceipt = createAsyncThunk(
  "get/Unregistered/lorryReceipt",
  async ({ invoiceId, sellerId }) => {
    try {
      // sessionStorage.getItem("InvoiceId") +
      // "?sellerId=" +
      // sessionStorage.getItem("SellerId") +
      // "&manualInvoice=false";
      const url = `${DOC_URL}swarm/docs/${sessionStorage.getItem(
        "SellerId"
      )}/${sessionStorage.getItem("InvoiceId")}/false/lorryReceipt`;
      const response = await axios.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

const lenderSlice = createSlice({
  name: "lender",
  initialState,
  reducers: {
    resetState: (state) => initialState,
  },
  extraReducers(builder) {
    builder

      /*******FROM LENDER DASHBOARD*****/
      /* GET CUSTOMER ONBOARD DATA */

      /* GET CORPORATE ONBOARD DATA */

      .addCase(postDeletApi.pending, (state, action) => {
        state.deleteApi.isLoading = true;
      })
      .addCase(postDeletApi.fulfilled, (state, action) => {
        state.deleteApi.data = action.payload;
        // state.createAgreement.error = null;
        state.deleteApi.isLoading = false;
      })
      .addCase(postDeletApi.rejected, (state, action) => {
        state.deleteApi.error = action.error.message;
        // state.createAgreement.data = null;
        state.deleteApi.isLoading = false;
      })

      .addCase(postModifyData.pending, (state, action) => {
        state.modifyApi.isLoading = true;
      })
      .addCase(postModifyData.fulfilled, (state, action) => {
        state.modifyApi.data = action.payload;
        // state.createAgreement.error = null;
        state.modifyApi.isLoading = false;
      })
      .addCase(postModifyData.rejected, (state, action) => {
        state.modifyApi.error = action.error.message;
        // state.createAgreement.data = null;
        state.modifyApi.isLoading = false;
      })

      .addCase(postDiscount.pending, (state, action) => {
        state.discountData.isLoading = true;
      })
      .addCase(postDiscount.fulfilled, (state, action) => {
        state.discountData.data = action.payload;
        // state.createAgreement.error = null;
        state.discountData.isLoading = false;
      })
      .addCase(postDiscount.rejected, (state, action) => {
        state.discountData.error = action.error.message;
        // state.createAgreement.data = null;
        state.discountData.isLoading = false;
      })

      .addCase(postAddUnderWriter.pending, (state, action) => {
        state.addUnderWriter.isLoading = true;
      })
      .addCase(postAddUnderWriter.fulfilled, (state, action) => {
        state.addUnderWriter.data = action.payload;
        // state.createAgreement.error = null;
        state.addUnderWriter.isLoading = false;
      })
      .addCase(postAddUnderWriter.rejected, (state, action) => {
        state.addUnderWriter.error = action.error.message;
        // state.createAgreement.data = null;
        state.addUnderWriter.isLoading = false;
      })

      .addCase(postKycData.pending, (state, action) => {
        state.kycdataUpdate.isLoading = true;
      })
      .addCase(postKycData.fulfilled, (state, action) => {
        state.kycdataUpdate.data = action.payload;
        // state.createAgreement.error = null;
        state.kycdataUpdate.isLoading = false;
      })
      .addCase(postKycData.rejected, (state, action) => {
        state.kycdataUpdate.error = action.error.message;
        // state.createAgreement.data = null;
        state.kycdataUpdate.isLoading = false;
      })

      .addCase(postUploadDoc.pending, (state, action) => {
        state.lenderPostDoc.isLoading = true;
      })
      .addCase(postUploadDoc.fulfilled, (state, action) => {
        state.lenderPostDoc.data = action.payload;
        // state.createAgreement.error = null;
        state.lenderPostDoc.isLoading = false;
      })
      .addCase(postUploadDoc.rejected, (state, action) => {
        state.lenderPostDoc.error = action.error.message;
        // state.createAgreement.data = null;
        state.lenderPostDoc.isLoading = false;
      })

      .addCase(postCreateData.pending, (state, action) => {
        state.createApi.isLoading = true;
      })
      .addCase(postCreateData.fulfilled, (state, action) => {
        state.createApi.data = action.payload;
        // state.createAgreement.error = null;
        state.createApi.isLoading = false;
      })
      .addCase(postCreateData.rejected, (state, action) => {
        state.createApi.error = action.error.message;
        // state.createAgreement.data = null;
        state.createApi.isLoading = false;
      })

      .addCase(postSendAgreement.pending, (state, action) => {
        state.sendAgreement.isLoading = true;
      })
      .addCase(postSendAgreement.fulfilled, (state, action) => {
        state.sendAgreement.data = action.payload;
        // state.createAgreement.error = null;
        state.sendAgreement.isLoading = false;
      })
      .addCase(postSendAgreement.rejected, (state, action) => {
        state.sendAgreement.error = action.error.message;
        // state.createAgreement.data = null;
        state.sendAgreement.isLoading = false;
      })

      .addCase(postAccept.pending, (state, action) => {
        state.acceptpo.isLoading = true;
      })
      .addCase(postAccept.fulfilled, (state, action) => {
        state.acceptpo.data = action.payload;
        // state.createAgreement.error = null;
        state.acceptpo.isLoading = false;
      })
      .addCase(postAccept.rejected, (state, action) => {
        state.acceptpo.error = action.error.message;
        // state.createAgreement.data = null;
        state.acceptpo.isLoading = false;
      })

      .addCase(postViewAgreement.pending, (state, action) => {
        state.viewAgreement.isLoading = true;
      })
      .addCase(postViewAgreement.fulfilled, (state, action) => {
        state.viewAgreement.data = action.payload;
        // state.createAgreement.error = null;
        state.viewAgreement.isLoading = false;
      })
      .addCase(postViewAgreement.rejected, (state, action) => {
        state.viewAgreement.error = action.error.message;
        // state.createAgreement.data = null;
        state.viewAgreement.isLoading = false;
      })

      .addCase(postUploadAgreement.pending, (state, action) => {
        state.uploadAgreement.isLoading = true;
      })
      .addCase(postUploadAgreement.fulfilled, (state, action) => {
        state.uploadAgreement.data = action.payload;
        // state.createAgreement.error = null;
        state.uploadAgreement.isLoading = false;
      })
      .addCase(postUploadAgreement.rejected, (state, action) => {
        state.uploadAgreement.error = action.error.message;
        // state.createAgreement.data = null;
        state.uploadAgreement.isLoading = false;
      })

      .addCase(postCreateAgreement.pending, (state, action) => {
        state.createAgreement.isLoading = true;
      })
      .addCase(postCreateAgreement.fulfilled, (state, action) => {
        state.createAgreement.data = action.payload;
        // state.createAgreement.error = null;
        state.createAgreement.isLoading = false;
      })
      .addCase(postCreateAgreement.rejected, (state, action) => {
        state.createAgreement.error = action.error.message;
        // state.createAgreement.data = null;
        state.createAgreement.isLoading = false;
      })

      .addCase(getIfscVerified.pending, (state, action) => {
        state.ifscVerify.isLoading = true;
      })
      .addCase(getIfscVerified.fulfilled, (state, action) => {
        state.ifscVerify.data = action.payload;
        state.ifscVerify.error = null;
        state.ifscVerify.isLoading = false;
      })
      .addCase(getIfscVerified.rejected, (state, action) => {
        state.ifscVerify.error = action.error.message;
        state.ifscVerify.data = null;
        state.ifscVerify.isLoading = false;
      })

      .addCase(getApproveLimit.pending, (state, action) => {
        state.approveLimit.isLoading = true;
      })
      .addCase(getApproveLimit.fulfilled, (state, action) => {
        state.approveLimit.data = action.payload;
        state.approveLimit.error = null;
        state.approveLimit.isLoading = false;
      })
      .addCase(getApproveLimit.rejected, (state, action) => {
        state.approveLimit.error = action.error.message;
        state.approveLimit.data = null;
        state.approveLimit.isLoading = false;
      })

      .addCase(getUserKYCDocument.pending, (state, action) => {
        state.kycData.isLoading = true;
      })
      .addCase(getUserKYCDocument.fulfilled, (state, action) => {
        state.kycData.data = action.payload;
        state.kycData.error = null;
        state.kycData.isLoading = false;
      })
      .addCase(getUserKYCDocument.rejected, (state, action) => {
        state.kycData.error = action.error.message;
        state.kycData.data = null;
        state.kycData.isLoading = false;
      })

      .addCase(getAgreementList.pending, (state, action) => {
        state.agreementList.isLoading = true;
      })
      .addCase(getAgreementList.fulfilled, (state, action) => {
        state.agreementList.data = action.payload;
        state.agreementList.error = null;
        state.agreementList.isLoading = false;
      })
      .addCase(getAgreementList.rejected, (state, action) => {
        state.agreementList.error = action.error.message;
        state.agreementList.data = null;
        state.agreementList.isLoading = false;
      })

      .addCase(getUnderWriter.pending, (state, action) => {
        state.underWriter.isLoading = true;
      })
      .addCase(getUnderWriter.fulfilled, (state, action) => {
        state.underWriter.data = action.payload;
        state.underWriter.error = null;
        state.underWriter.isLoading = false;
      })
      .addCase(getUnderWriter.rejected, (state, action) => {
        state.underWriter.error = action.error.message;
        state.underWriter.data = null;
        state.underWriter.isLoading = false;
      })

      .addCase(getRiskManagementData.pending, (state, action) => {
        state.corporateOnboardData.isLoading = true;
      })
      .addCase(getRiskManagementData.fulfilled, (state, action) => {
        state.corporateOnboardData.data = action.payload;
        state.corporateOnboardData.error = null;
        state.corporateOnboardData.isLoading = false;
      })
      .addCase(getRiskManagementData.rejected, (state, action) => {
        state.corporateOnboardData.error = action.error.message;
        state.corporateOnboardData.data = null;
        state.corporateOnboardData.isLoading = false;
      })

      .addCase(getSearchApi1.pending, (state, action) => {
        state.billLoding3.isLoading = true;
      })
      .addCase(getSearchApi1.fulfilled, (state, action) => {
        state.billLoding3.data = action.payload;
        state.billLoding3.error = null;
        state.billLoding3.isLoading = false;
      })
      .addCase(getSearchApi1.rejected, (state, action) => {
        state.billLoding3.error = action.error.message;
        state.billLoding3.data = null;
        state.billLoding3.isLoading = false;
      })

      .addCase(getSearchApi.pending, (state, action) => {
        state.billLoding2.isLoading = true;
      })
      .addCase(getSearchApi.fulfilled, (state, action) => {
        state.billLoding2.data = action.payload;
        state.billLoding2.error = null;
        state.billLoding2.isLoading = false;
      })
      .addCase(getSearchApi.rejected, (state, action) => {
        state.billLoding2.error = action.error.message;
        state.billLoding2.data = null;
        state.billLoding2.isLoading = false;
      })

      .addCase(getBillLodging.pending, (state, action) => {
        state.billLoding.isLoading = true;
      })
      .addCase(getBillLodging.fulfilled, (state, action) => {
        state.billLoding.data = action.payload;
        state.billLoding.error = null;
        state.billLoding.isLoading = false;
      })
      .addCase(getBillLodging.rejected, (state, action) => {
        state.billLoding.error = action.error.message;
        state.billLoding.data = null;
        state.billLoding.isLoading = false;
      })

      .addCase(getBillLodgingExcel.pending, (state, action) => {
        state.billLodingExcel.isLoading = true;
      })
      .addCase(getBillLodgingExcel.fulfilled, (state, action) => {
        state.billLodingExcel.data = action.payload;
        state.billLodingExcel.error = null;
        state.billLodingExcel.isLoading = false;
      })
      .addCase(getBillLodgingExcel.rejected, (state, action) => {
        state.billLodingExcel.error = action.error.message;
        state.billLodingExcel.data = null;
        state.billLodingExcel.isLoading = false;
      })

      .addCase(getRepaid.pending, (state, action) => {
        state.repaidData.isLoading = true;
      })
      .addCase(getRepaid.fulfilled, (state, action) => {
        state.repaidData.data = action.payload;
        state.repaidData.error = null;
        state.repaidData.isLoading = false;
      })
      .addCase(getRepaid.rejected, (state, action) => {
        state.repaidData.error = action.error.message;
        state.repaidData.data = null;
        state.repaidData.isLoading = false;
      })

      .addCase(getBillLodgingDigital.pending, (state, action) => {
        state.billLodgingDigital.isLoading = true;
        state.billLodgingDigital.data = null;
        state.billLodgingDigital.error = null;
      })
      .addCase(getBillLodgingDigital.fulfilled, (state, action) => {
        state.billLodgingDigital.data = action.payload;
        state.billLodgingDigital.error = null;
        state.billLodgingDigital.isLoading = false;
      })
      .addCase(getBillLodgingDigital.rejected, (state, action) => {
        state.billLodgingDigital.error = action.error.message;
        state.billLodgingDigital.data = null;
        state.billLodgingDigital.isLoading = false;
      })

      .addCase(getBillLodgingManual.pending, (state, action) => {
        state.billLodgingManual.isLoading = true;
        state.billLodgingManual.error = null;
        state.billLodgingManual.data = null;
      })
      .addCase(getBillLodgingManual.fulfilled, (state, action) => {
        state.billLodgingManual.data = action.payload;
        state.billLodgingManual.error = null;
        state.billLodgingManual.isLoading = false;
      })
      .addCase(getBillLodgingManual.rejected, (state, action) => {
        state.billLodgingManual.error = action.error.message;
        state.billLodgingManual.data = null;
        state.billLodgingManual.isLoading = false;
      })

      .addCase(getBillLodging1.pending, (state, action) => {
        state.billLoding1.isLoading = true;
      })
      .addCase(getBillLodging1.fulfilled, (state, action) => {
        state.billLoding1.data = action.payload;
        state.billLoding1.error = null;
        state.billLoding1.isLoading = false;
      })
      .addCase(getBillLodging1.rejected, (state, action) => {
        state.billLoding1.error = action.error.message;
        state.billLoding1.data = null;
        state.billLoding1.isLoading = false;
      })

      .addCase(getataInvoiceD.pending, (state, action) => {
        state.invoicedata.isLoading = true;
      })
      .addCase(getataInvoiceD.fulfilled, (state, action) => {
        state.invoicedata.data = action.payload;
        state.invoicedata.error = null;
        state.invoicedata.isLoading = false;
      })
      .addCase(getataInvoiceD.rejected, (state, action) => {
        state.invoicedata.error = action.error.message;
        state.invoicedata.data = null;
        state.invoicedata.isLoading = false;
      })

      .addCase(getTransactionData.pending, (state, action) => {
        state.Transacdata.isLoading = true;
      })
      .addCase(getTransactionData.fulfilled, (state, action) => {
        state.Transacdata.data = action.payload;
        state.Transacdata.error = null;
        state.Transacdata.isLoading = false;
      })
      .addCase(getTransactionData.rejected, (state, action) => {
        state.Transacdata.error = action.error.message;
        state.Transacdata.data = null;
        state.Transacdata.isLoading = false;
      })

      .addCase(getPurchaseOrder.pending, (state, action) => {
        state.PurchaseData.isLoading = true;
      })
      .addCase(getPurchaseOrder.fulfilled, (state, action) => {
        state.PurchaseData.data = action.payload;
        state.PurchaseData.error = null;
        state.PurchaseData.isLoading = false;
      })
      .addCase(getPurchaseOrder.rejected, (state, action) => {
        state.PurchaseData.error = action.error.message;
        state.PurchaseData.data = null;
        state.PurchaseData.isLoading = false;
      })

      .addCase(getDropDownData.pending, (state, action) => {
        state.dropDown.isLoading = true;
      })
      .addCase(getDropDownData.fulfilled, (state, action) => {
        state.dropDown.data = action.payload;
        state.dropDown.error = null;
        state.dropDown.isLoading = false;
      })
      .addCase(getDropDownData.rejected, (state, action) => {
        state.dropDown.error = action.error.message;
        state.dropDown.data = null;
        state.dropDown.isLoading = false;
      })

      .addCase(getUnregisteredBillDetail.pending, (state, action) => {
        state.unregisteredData.isLoading = true;
      })
      .addCase(getUnregisteredBillDetail.fulfilled, (state, action) => {
        state.unregisteredData.data = action.payload;
        state.unregisteredData.error = null;
        state.unregisteredData.isLoading = false;
      })
      .addCase(getUnregisteredBillDetail.rejected, (state, action) => {
        state.unregisteredData.error = action.error.message;
        state.unregisteredData.data = null;
        state.unregisteredData.isLoading = false;
      })

      .addCase(getBillDetail.pending, (state, action) => {
        state.billData.isLoading = true;
      })
      .addCase(getBillDetail.fulfilled, (state, action) => {
        state.billData.data = action.payload;
        state.billData.error = null;
        state.billData.isLoading = false;
      })
      .addCase(getBillDetail.rejected, (state, action) => {
        state.billData.error = action.error.message;
        state.billData.data = null;
        state.billData.isLoading = false;
      })

      .addCase(getDisbursedData.pending, (state, action) => {
        state.disburshedData.isLoading = true;
        state.disburshedData.data = null;
        state.disburshedData.error = null;
      })
      .addCase(getDisbursedData.fulfilled, (state, action) => {
        state.disburshedData.data = action.payload;
        state.disburshedData.error = null;
        state.disburshedData.isLoading = false;
      })
      .addCase(getDisbursedData.rejected, (state, action) => {
        state.disburshedData.error = action.error.message;
        state.disburshedData.data = null;
        state.disburshedData.isLoading = false;
      })
      // This is Liquidation Due Date
      .addCase(getLiquidDationDue.pending, (state, action) => {
        state.LiquidDationDueData.isLoading = true;
      })
      .addCase(getLiquidDationDue.fulfilled, (state, action) => {
        state.LiquidDationDueData.data = action.payload;
        state.LiquidDationDueData.error = null;
        state.LiquidDationDueData.isLoading = false;
      })
      .addCase(getLiquidDationDue.rejected, (state, action) => {
        state.LiquidDationDueData.error = action.error.message;
        state.LiquidDationDueData.data = null;
        state.LiquidDationDueData.isLoading = false;
      })

      // This is Business Growth Contributors
      .addCase(getBusinessGrowthContributors.pending, (state, action) => {
        state.BusinessGrowthContributorsData.isLoading = true;
      })
      .addCase(getBusinessGrowthContributors.fulfilled, (state, action) => {
        state.BusinessGrowthContributorsData.data = action.payload;
        state.BusinessGrowthContributorsData.error = null;
        state.BusinessGrowthContributorsData.isLoading = false;
      })
      .addCase(getBusinessGrowthContributors.rejected, (state, action) => {
        state.BusinessGrowthContributorsData.error = action.error.message;
        state.BusinessGrowthContributorsData.data = null;
        state.BusinessGrowthContributorsData.isLoading = false;
      })

      .addCase(updateCreditLimitStatus.pending, (state, action) => {
        state.updateCreditLimitStatusData.isLoading = true;
      })
      .addCase(updateCreditLimitStatus.fulfilled, (state, action) => {
        state.updateCreditLimitStatusData.isLoading = false;
        state.updateCreditLimitStatusData.data = action.payload;
        state.updateCreditLimitStatusData.error = null;
      })
      .addCase(updateCreditLimitStatus.rejected, (state, action) => {
        state.updateCreditLimitStatusData.isLoading = false;
        state.updateCreditLimitStatusData.error = action.error.message;
        state.updateCreditLimitStatusData.data = null;
      })

      .addCase(getLenderDisbursedInvoiceTable.pending, (state, action) => {
        state.getLenderDisbursedInvoiceTableData.isLoading = true;
      })
      .addCase(getLenderDisbursedInvoiceTable.fulfilled, (state, action) => {
        state.getLenderDisbursedInvoiceTableData.isLoading = false;
        state.getLenderDisbursedInvoiceTableData.data = action.payload;
      })
      .addCase(getLenderDisbursedInvoiceTable.rejected, (state, action) => {
        state.getLenderDisbursedInvoiceTableData.isLoading = false;
        state.getLenderDisbursedInvoiceTableData.error = action.error.message;
      })

      .addCase(uploadLenderDisbursedInvoice.pending, (state, action) => {
        state.uploadLenderDisbursedInvoiceData.isLoading = true;
      })
      .addCase(uploadLenderDisbursedInvoice.fulfilled, (state, action) => {
        state.uploadLenderDisbursedInvoiceData.isLoading = false;
        state.uploadLenderDisbursedInvoiceData.data = action.payload;
      })
      .addCase(uploadLenderDisbursedInvoice.rejected, (state, action) => {
        state.uploadLenderDisbursedInvoiceData.isLoading = false;
        state.uploadLenderDisbursedInvoiceData.error = action.error.message;
      })

      .addCase(uploadLenderRepayment.pending, (state, action) => {
        state.uploadLenderRepaymentData.isLoading = true;
      })
      .addCase(uploadLenderRepayment.fulfilled, (state, action) => {
        state.uploadLenderRepaymentData.isLoading = false;
        state.uploadLenderRepaymentData.data = action.payload;
      })
      .addCase(uploadLenderRepayment.rejected, (state, action) => {
        state.uploadLenderRepaymentData.isLoading = false;
        state.uploadLenderRepaymentData.error = action.error.message;
      })


      .addCase(getLenderManualInoviceClick.pending, (state, action) => {
        state.getLenderManualInoviceClickData.isLoading = true;
      })
      .addCase(getLenderManualInoviceClick.fulfilled, (state, action) => {
        state.getLenderManualInoviceClickData.isLoading = false;
        state.getLenderManualInoviceClickData.data = action.payload;
      })
      .addCase(getLenderManualInoviceClick.rejected, (state, action) => {
        state.getLenderManualInoviceClickData.isLoading = false;
        state.getLenderManualInoviceClickData.error = action.error.message;
      })

      .addCase(getUnRegisteredBillOfExchange.pending, (state, action) => {
        state.unregisteredBillOfExchange.isLoading = true;
        state.unregisteredBillOfExchange.data = null;
        state.unregisteredBillOfExchange.error = null;
      })
      .addCase(getUnRegisteredBillOfExchange.fulfilled, (state, action) => {
        state.unregisteredBillOfExchange.isLoading = false;
        state.unregisteredBillOfExchange.data = action.payload;
        state.unregisteredBillOfExchange.error = null;
      })
      .addCase(getUnRegisteredBillOfExchange.rejected, (state, action) => {
        state.unregisteredBillOfExchange.isLoading = false;
        state.unregisteredBillOfExchange.error = action.error.message;
        state.unregisteredBillOfExchange.data = null;
      })

      .addCase(getUnRegisteredLorryReceipt.pending, (state, action) => {
        state.getLenderManualInoviceClickData.isLoading = true;
        state.unregisteredBillOfExchange.data = null;
        state.unregisteredBillOfExchange.error = null;
      })
      .addCase(getUnRegisteredLorryReceipt.fulfilled, (state, action) => {
        state.getLenderManualInoviceClickData.isLoading = false;
        state.getLenderManualInoviceClickData.data = action.payload;
        state.unregisteredBillOfExchange.error = null;
      })
      .addCase(getUnRegisteredLorryReceipt.rejected, (state, action) => {
        state.getLenderManualInoviceClickData.isLoading = false;
        state.getLenderManualInoviceClickData.error = action.error.message;
        state.unregisteredBillOfExchange.data = null;
      })

      .addCase(fetchCounterPartyUsingAnchorId.pending, (state, action) => {
        state.fetchCounterPartyUsingAnchorId.isLoading = true;
        state.fetchCounterPartyUsingAnchorId.data = null;
        state.fetchCounterPartyUsingAnchorId.error = null;
      })
      .addCase(fetchCounterPartyUsingAnchorId.fulfilled, (state, action) => {
        state.fetchCounterPartyUsingAnchorId.isLoading = false;
        state.fetchCounterPartyUsingAnchorId.data = action.payload;
        state.fetchCounterPartyUsingAnchorId.error = null;
      })
      .addCase(fetchCounterPartyUsingAnchorId.rejected, (state, action) => {
        state.fetchCounterPartyUsingAnchorId.isLoading = false;
        state.fetchCounterPartyUsingAnchorId.error = action.error.message;
        state.fetchCounterPartyUsingAnchorId.data = null;
      })

      .addCase(fetchAccountReports.pending, (state, action) => {
        state.fetchReports.isLoading = true;
        state.fetchReports.data = null;
        state.fetchReports.error = null;
      })
      .addCase(fetchAccountReports.fulfilled, (state, action) => {
        state.fetchReports.isLoading = false;
        state.fetchReports.data = action.payload;
        state.fetchReports.error = null;
      })
      .addCase(fetchAccountReports.rejected, (state, action) => {
        state.fetchReports.isLoading = false;
        state.fetchReports.error = action.error.message;
        state.fetchReports.data = null;
      })
      .addCase(getDashboard.pending, (state, action) => {
        state.dashboardData.isLoading = true;
        state.dashboardData.data = null;
        state.dashboardData.error = null;
      })
      .addCase(getDashboard.fulfilled, (state, action) => {
        state.dashboardData.isLoading = false;
        state.dashboardData.data = action.payload;
        state.dashboardData.error = null;
      })
      .addCase(getDashboard.rejected, (state, action) => {
        state.dashboardData.isLoading = false;
        state.dashboardData.error = action.error.message;
        state.dashboardData.data = null;
      })
      .addCase(getInvoiceStatus.pending, (state, action) => {
        state.invoiceStatus.isLoading = true;
        state.invoiceStatus.data = null;
        state.invoiceStatus.error = null;
      })
      .addCase(getInvoiceStatus.fulfilled, (state, action) => {
        state.invoiceStatus.isLoading = false;
        state.invoiceStatus.data = action.payload;
        state.invoiceStatus.error = null;
      })
      .addCase(getInvoiceStatus.rejected, (state, action) => {
        state.invoiceStatus.isLoading = false;
        state.invoiceStatus.error = action.error.message;
        state.invoiceStatus.data = null;
      })
      .addCase(discountingSeller.pending, (state, action) => {
        state.discountingSellerData.isLoading = true;
        state.discountingSellerData.data = null;
        state.discountingSellerData.error = null;
      })
      .addCase(discountingSeller.fulfilled, (state, action) => {
        state.discountingSellerData.isLoading = false;
        state.discountingSellerData.data = action.payload;
        state.discountingSellerData.error = null;
      })
      .addCase(discountingSeller.rejected, (state, action) => {
        state.discountingSellerData.isLoading = false;
        state.discountingSellerData.error = action.error.message;
        state.discountingSellerData.data = null;
      })

      .addCase(buyerByAnchor.pending, (state, action) => {
        state.buyerByAnchorData.isLoading = true;
        state.buyerByAnchorData.data = null;
        state.buyerByAnchorData.error = null;
      })
      .addCase(buyerByAnchor.fulfilled, (state, action) => {
        state.buyerByAnchorData.isLoading = false;
        state.buyerByAnchorData.data = action.payload;
        state.buyerByAnchorData.error = null;
      })
      .addCase(buyerByAnchor.rejected, (state, action) => {
        state.buyerByAnchorData.isLoading = false;
        state.buyerByAnchorData.error = action.error.message;
        state.buyerByAnchorData.data = null;
      });

  },
});

export const { resetState } = lenderSlice.actions;
export default lenderSlice.reducer;
