// -----------------------------------------------------------
// Local Imports
// -----------------------------------------------------------

import "./App.scss";
import "./style/components/lender.scss";

// -----------------------------------------------------------
// Packages Import (pkg)
// -----------------------------------------------------------
import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements
} from "react-router-dom";

// -----------------------------------------------------------
// Protected Routes
// -----------------------------------------------------------
import AdminPrivateRoute from "./PrivateRoutes/AdminPrivateRoute";
import AnchorPrivateRoute from "./PrivateRoutes/AnchorPrivateRoute";
import BuyerPrivateRoute from "./PrivateRoutes/BuyerPrivateRoute";
import KyclenderPrivateRoute from "./PrivateRoutes/KyclenderPrivateRoute";
import LenderPrivateRoute from "./PrivateRoutes/LenderPrivateRoute";

// -----------------------------------------------------------
// Generic Import (local)
// -----------------------------------------------------------
import ErrorPage from "./Pages/ErrorPage";
import Login from "./Pages/Login";
import MobileVerification from "./Pages/MobileVerification";
import OtpVerification from "./Pages/OtpVerification";
import SelectRole from "./Pages/SelectRole";
import SignUp from "./Pages/SignUp";
import GenericLayout from "./layout/GenericLayout/GenericLayout";

// -------------------------------------------------------------
// Admin Modules imports (local)
// -------------------------------------------------------------
import AdminAnchorAndLender from "./Components/Admin/AdminAnchorAndLender";
import AdminChannelPartner from "./Components/Admin/AdminChannelPartner";
import AdminDashboard from "./Components/Admin/AdminDashboard";
import AdminLender from "./Components/Admin/AdminLender";
import AdminRelationshipManager from "./Components/Admin/AdminRelationshipManager";
import AdminSuperAdmin from "./Components/Admin/AdminSuperAdmin";
import AdminUsers from "./Components/Admin/AdminUsers";
import AdminLayout from "./layout/AdminLayout/AdminLayout";

// -------------------------------------------------------------
//  Buyer Modules imports (local)
// -------------------------------------------------------------
import BuyerDashboard from "./Components/Buyer/BuyerDashboard";
import BuyerBillOfExchange from "./Components/Buyer/BuyerInvoice/BuyerBillOfExchange";
import BuyerInvoice from "./Components/Buyer/BuyerInvoice/BuyerInvoice";
import BuyerInvoiceDigitalDetails from "./Components/Buyer/BuyerInvoice/BuyerInvoiceDigitalDetails";
import BuyerProformaInvoice from "./Components/Buyer/BuyerProformaInvoice/BuyerProformaInvoice";
import BuyerProformaInvoiceDetails from "./Components/Buyer/BuyerProformaInvoice/BuyerProformaInvoiceDetails";
import BuyerPurchaseOrder from "./Components/Buyer/BuyerPurchaseOrder/BuyerPurchaseOrder";
import BuyerPurchaseOrderDetails from "./Components/Buyer/BuyerPurchaseOrder/BuyerPurchaseOrderDetails";
import BuyerCreateMaterialRequest from "./Components/Buyer/MaterialRequest/BuyerCreateMaterialRequest";
import BuyerMaterialRequest from "./Components/Buyer/MaterialRequest/BuyerMaterialRequest";
import MaterialRequestDetails from "./Components/Buyer/MaterialRequest/MaterialRequestDetails";
import BuyerLayout from "./layout/BuyerLayout/BuyerLayout";
import BuyerInvRefrence from "./Components/Buyer/BuyerReferenceNo/BuyerInvRefrence";

// -------------------------------------------------------------
// Anchor Modules imports (local)
// -------------------------------------------------------------
import AnchorAgreement from "./Components/Anchor/AnchorAgreement";
import AnchorCounterParty from "./Components/Anchor/AnchorCounterParty";
import AnchorDashboard from "./Components/Anchor/AnchorDashboard";
import AnchorDisbursedInvoice from "./Components/Anchor/AnchorDisbursedInvoice";
import AnchorInvoice from "./Components/Anchor/AnchorInvoice";
import AnchorBulkInvoice from "./Components/Anchor/AnchorBulkInvoice";
import AnchorMaterialRequest from "./Components/Anchor/AnchorMaterialRequest";
import AnchorPaymentReconciliation from "./Components/Anchor/AnchorPaymentReconciliation";
import AnchorProformaInvoice from "./Components/Anchor/AnchorProformaInvoice";
import AnchorPurchaseOrder from "./Components/Anchor/AnchorPurchaseOrder";
import AnchorLayout from "./layout/AnchorLayout/AnchorLayout";
import InvReferenceNumber from "./Components/Anchor/InvReference";

// -------------------------------------------------------------
// Lender Modules imports (local)
// -------------------------------------------------------------
import BillDetail from "./Components/Lender/BillDetail";
import InvoiceDetail from "./Components/Lender/Invoicedetail";
import LenderAgreement from "./Components/Lender/LenderAgreement";
import LenderApproveLimit from "./Components/Lender/LenderApproveLimit";
import LenderBillLogding from "./Components/Lender/LenderBillLogding";
import LenderDashboard from "./Components/Lender/LenderDashboard";
import LenderDisbursed from "./Components/Lender/LenderDisbursed";
import LenderLiquidation from "./Components/Lender/LenderLiquidation";
import LenderNewTransactionMaintenance from "./Components/Lender/LenderNewTransactionMaintenance";
import LenderPurchaseOrder from "./Components/Lender/LenderPurchaseOrder";
import LenderReports from "./Components/Lender/LenderReports";
import LenderRishManagementSystem from "./Components/Lender/LenderRiskManagementSystem";
import LenderTransactionMaintenance from "./Components/Lender/LenderTransactionMaintenance";
import LenderUnderWriter from "./Components/Lender/LenderUnderWriter";
import UnregisteredBillDetail from "./Components/Lender/UnregisteredBillDetail";
import LenderLayout from "./layout/LenderLayout/LenderLayout";
// import KycVerificationForm from "./Pages/KycVerificationForm";
import DigitalInvoiceDetailsById from "./Components/Anchor/AnchorTables/AnchorInvoiceTables.js/DigitalInvoiceDetailsById";
import AnchorPaymentReconciliationInvoiceDetails from "./Components/Anchor/AnchorTables/AnchorPaymentReconciliation/AnchorPaymentReconciliationInvoiceDetails";
import AnchorProformaInvoiceDetailsById from "./Components/Anchor/AnchorTables/AnchorProformaInvoice/AnchorProformaInvoiceDetailsById";
import DigitalPurchaseOrderDetailsById from "./Components/Anchor/AnchorTables/PurchaseOrderTables.js/DigitalPurchaseOrderDetailsById";
import KycVerificationLenderForm from "./Pages/KycVerificationLenderForm";

/*--------------------------------------------------------------*/

// import Kyclayout from "./layout/KycLayout/kyclayout";
import AdminKyc from "./Components/Admin/KYC/AdminKyc";
import AdminLenderKYC from "./Components/Admin/KYC/AdminLenderKYC";
import AnchorInventory from "./Components/Anchor/AnchorInventory";
import AnchorKYC from "./Components/Anchor/AnchorKyc";
import AnchorMyProfile from "./Components/Anchor/AnchorMyProfile";
import BuyerKyc from "./Components/Buyer/BuyerKyc";
import BuyerMyProfile from "./Components/Buyer/BuyerMyProfile";
import ChannelCustomerOnboarding from "./Components/Channel/ChannelCustomerOnboarding";
import ChannelDashboard from "./Components/Channel/ChannelDashboard";
import ChannelOnboardAnchor from "./Components/Channel/ChannelOnBoard/ChannelOnboardAnchor";
import ChannelOnboardCounterParty from "./Components/Channel/ChannelOnBoard/ChannelOnboardCounterParty";
import CorporateCustomerOnboarding from "./Components/Corporate/CorporateCustomerOnboarding";
import CorporateDashboard from "./Components/Corporate/CorporateDashboard";
import CorporateOnboardAnchor from "./Components/Corporate/CorporateOnBoard/CorporateOnboardAnchor";
import CorporateOnboardCounterParty from "./Components/Corporate/CorporateOnBoard/CorporateOnboardCounterParty";
import ChannelPrivateRoute from "./PrivateRoutes/ChannelPrivateRoute";
import CorporatePrivateRoute from "./PrivateRoutes/CorporatePrivateRoute";
import RMPrivateRoute from "./PrivateRoutes/RMPrivateRoute";
import ChannelLayout from "./layout/ChannelLayout/ChannelLayout";
import CorporateLayout from "./layout/CorporateLayout/CorporateLayout";
import Kyclayout from "./layout/KycLayout/kyclayout";

import AnchorMaterialRequestTableById from "./Components/Anchor/AnchorTables/AnchorMaterialRequestTableById";
import ChannelPartnerKyc from "./Components/Channel/channelPartnerKYC";
import CorporateKyc from "./Components/Corporate/corporateKyc";
import LenderDisbursedInvoice from "./Components/Lender/LenderDisbursedInvoice";
import RMCustomerOnboarding from "./Components/RelationshipManager/RMCustomerOnboarding";
import RMInvoices from "./Components/RelationshipManager/RMInvoices";
import RMDashboard from "./Components/RelationshipManager/RMDashboard";
import UnderwriterLimit from "./Components/Underwriter/UnderwriterLimit";
import UnderwriterMyProfile from "./Components/Underwriter/UnderwriterMyProfile";
import UnderWriterPrivateRoute from "./PrivateRoutes/UnderWriterPrivateRoute";
import RelationShipManagerLayout from "./layout/RelationshipManagerLayout/RelationShipManagerLayout";
import UnderWriterLayout from "./layout/UnderWriterLayout/UnderWriterLayout";

import AdminUserKyc from "./Components/Admin/KYC/AdminUserKyc";
import LenderBillLodge from "./Components/Lender/LenderBillLodge";
import LenderMyProfile from "./Components/Lender/LenderMyProfile";
import { EmailTokenVerification } from "./Pages/email_confirmation/EmailConfirmation";
import {ApproveUser} from "./Pages/approve_reject_user/approveUser";
import {RejectUser} from "./Pages/approve_reject_user/rejectUser";

import { GlobalErrorBoundary } from "./errorBoundary";
import ResetPassword from "./Pages/ResetPassword";
import { ResetPasswordError } from "./Pages/ResetPasswordError";
import { BuyerBatchInvoice } from "./Components/Buyer/BatchInvoice";
import { AdminAuditTrail } from "./Components/Admin/audit_trail";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<GlobalErrorBoundary />}>
      {/* General Routes */}
      <Route path="/" element={<GenericLayout />}>
        <Route index element={<Navigate to="login" />} />
        <Route path="login" element={<Login />} />
        <Route path="user/reset_password" element={ <ResetPasswordError /> } />
        <Route path="user/reset_password/:emailId/:token" element={<ResetPassword />} />
        <Route path="select-role" element={<SelectRole />} />
        <Route path="sign-up" element={<SignUp />} />
        <Route path="mobile-verification" element={<MobileVerification />} />
        <Route path="otp-verification" element={<OtpVerification />} />
      </Route>

      <Route
        path="/user/confirm_email/:token"
        element={<EmailTokenVerification />}
      />
      <Route
        path="/user/approve_token/:token"
        element={<ApproveUser />}
      />
      <Route
        path="/user/reject_token/:token"
        element={<RejectUser />}
      />
      

      {/* Admin Routes */}
      <Route element={<AdminPrivateRoute />} path="/admin">
        <Route element={<AdminLayout />}>
          <Route index element={<Navigate to="./dashboard" />} />
          <Route path="dashboard" element={<AdminDashboard />} />

          <Route path="anchor-and-lender" element={<AdminAnchorAndLender />} />
          <Route path="users" element={<AdminUsers />} />
          <Route path="channel-partner" element={<AdminChannelPartner />} />
          <Route
            path="relationship-manager"
            element={<AdminRelationshipManager />}
          />
          <Route path="super-admin" element={<AdminSuperAdmin />} />
          <Route path="lender" element={<AdminLender />} />
          {/* KYC */}
          <Route path="dashboard/kyc/:emailId" element={<AdminKyc />} />
          <Route path="users/kyc/:emailId" element={<AdminUserKyc />} />
          <Route path="channel-partner/kyc/:emailId" element={<AdminKyc />} />

          <Route path="lender/kyc/:emailId" element={<AdminLenderKYC />} />
          <Route path="audit_trail" element={ <AdminAuditTrail /> } />
          <Route path="*" element={<Navigate to="/not-found" />} />
        </Route>
      </Route>

      {/* Verification Routes */}
      <Route>
        <Route path="seller/kyc-verification-form" element={<AnchorKYC />} />
        <Route path="buyer/kyc-verification-form" element={<BuyerKyc />} />
        <Route
          path="channel/kyc-verification-form"
          element={<ChannelPartnerKyc />}
        />
        <Route
          path="corporate/kyc-verification-form"
          element={<CorporateKyc />}
        />
      </Route>

      <Route element={<KyclenderPrivateRoute />} path="/kyc">
        <Route element={<Kyclayout />}>
          <Route
            path="kyc-verification-form"
            element={<KycVerificationLenderForm />}
          />

          <Route />
        </Route>
      </Route>

      {/* Buyer Routes */}
      <Route element={<BuyerPrivateRoute />} path="/buyer">
        <Route element={<BuyerLayout />}>
          <Route index element={<Navigate to="./dashboard" />} />
          <Route path="dashboard" element={<BuyerDashboard />} />
          <Route path="my-profile" element={<BuyerMyProfile />} />
          <Route path="invoice/">
            <Route index element={<BuyerInvoice />} />
            <Route
              path=":invoiceNumber"
              element={<BuyerInvoiceDigitalDetails />}
            />
            <Route path="BOE/:BOENumber" element={<BuyerBillOfExchange />} />
          </Route>
          <Route path="invoice" element={<BuyerInvoice />} />
          <Route path="bulk_invoice" element={ <BuyerBatchInvoice /> } />
          <Route path="material-request/">
            <Route index element={<BuyerMaterialRequest />} />
            <Route path=":mr" element={<MaterialRequestDetails />} />
            <Route path="add" element={<BuyerCreateMaterialRequest />} />
          </Route>
          <Route path="proforma-invoice/">
            <Route index element={<BuyerProformaInvoice />} />
            <Route
              path=":paramsProformaInvoiceID"
              element={<BuyerProformaInvoiceDetails />}
            />
          </Route>

          <Route path="purchase-order/">
            <Route index element={<BuyerPurchaseOrder />} />

            <Route
              path=":paramsPurchaseOrderID"
              element={<BuyerPurchaseOrderDetails />}
            />
          </Route>

          <Route path="INV-reference-number" element={<BuyerInvRefrence />} />

          <Route path="*" element={<Navigate to="/not-found" />} />
        </Route>
      </Route>

      {/* Lender Routes */}
      <Route element={<LenderPrivateRoute />} path="/lender">
        <Route element={<LenderLayout />}>
          <Route index element={<Navigate to="./dashboard" />} />
          <Route path="my-profile" element={<LenderMyProfile />} />
          <Route path="dashboard" element={<LenderDashboard />} />
          <Route path="agreement" element={<LenderAgreement />} />
          <Route path="approve-limit" element={<LenderApproveLimit />} />
          <Route path="bill-lodging" element={<LenderBillLogding />} />
          <Route path="bill-lodging" element={<LenderBillLodge />} />

          <Route
            path="bill-lodging/invoice-details"
            element={<InvoiceDetail />}
          />
          <Route
            path="bill-lodging/unregistered_bill"
            element={<UnregisteredBillDetail />}
          />
          <Route path="bill-lodging/bill-deails" element={<BillDetail />} />
          <Route path="disbursed" element={<LenderDisbursed />} />
          <Route path="liquidation" element={<LenderLiquidation />} />
          <Route path="purchase-order" element={<LenderPurchaseOrder />} />
          <Route path="report" element={<LenderReports />} />
          <Route
            path="risk-management"
            element={<LenderRishManagementSystem />}
          />
          <Route
            path="trans-maintain"
            element={<LenderTransactionMaintenance />}
          />
          <Route
            path="trans-maintain/new-trans-maintain"
            element={<LenderNewTransactionMaintenance />}
          />
          <Route
            path="disbursed-invoices"
            element={<LenderDisbursedInvoice />}
          />
          <Route path="under-writer" element={<LenderUnderWriter />} />
          <Route path="*" element={<Navigate to="/not-found" />} />
        </Route>
      </Route>

      {/* Anchor Routes */}
      <Route element={<AnchorPrivateRoute />} path="/anchor">
        <Route element={<AnchorLayout />}>
          {/* <Route element={<AnchorLayout />}> */}
          <Route index element={<Navigate to="./dashboard" />} />
          <Route path="dashboard" element={<AnchorDashboard />} />
          <Route path="my-profile" element={<AnchorMyProfile />} />
          <Route path="view-inventory" element={<AnchorInventory />} />
          <Route path="material-request" element={<AnchorMaterialRequest />} />
          <Route
            path="material-request/:buyerId/:mrId"
            element={<AnchorMaterialRequestTableById />}
          />
          <Route path="proforma-invoice" element={<AnchorProformaInvoice />} />
          <Route
            path="proforma-invoice/:sellerId/:proformaId"
            element={<AnchorProformaInvoiceDetailsById />}
          />
          <Route path="purchase-order" element={<AnchorPurchaseOrder />} />
          <Route path="INV-reference-number" element={<InvReferenceNumber />} />
          <Route
            path="purchase-order/digital/:buyerId/:proformaId"
            element={<DigitalPurchaseOrderDetailsById />}
          />
          <Route path="invoice" element={<AnchorInvoice />} />
          <Route path="bulk_invoice" element={<AnchorBulkInvoice />} />
          <Route
            path="invoice/digital/:sellerId/:invoiceId"
            element={<DigitalInvoiceDetailsById />}
          />
          <Route path="disbursed" element={<AnchorDisbursedInvoice />} />
          <Route path="payment" element={<AnchorPaymentReconciliation />} />
          <Route
            path="payment/:sellerId/:invoiceId"
            element={<AnchorPaymentReconciliationInvoiceDetails />}
          />
          <Route path="counter-party" element={<AnchorCounterParty />} />
          <Route path="agreement" element={<AnchorAgreement />} />
          <Route path="*" element={<Navigate to="/not-found" />} />
        </Route>
        {/* </Route> */}
      </Route>

      {/* Channel Routes */}
      <Route element={<ChannelPrivateRoute />} path="/channel-partner">
        <Route element={<ChannelLayout />}>
          <Route index element={<Navigate to="./dashboard" />} />
          <Route path="dashboard" element={<ChannelDashboard />} />
          <Route
            path="customer-onboarding"
            element={<ChannelCustomerOnboarding />}
          />

          <Route
            path="customer-onboarding/anchor"
            element={<ChannelOnboardAnchor />}
          />

          <Route
            path="customer-onboarding/counter-party"
            element={<ChannelOnboardCounterParty />}
          />
        </Route>
      </Route>

      {/* Corporate */}
      <Route element={<CorporatePrivateRoute />} path="/corporate">
        <Route element={<CorporateLayout />}>
          <Route index element={<Navigate to="./dashboard" />} />
          <Route path="dashboard" element={<CorporateDashboard />} />
          <Route
            path="customer-onboarding"
            element={<CorporateCustomerOnboarding />}
          />

          <Route
            path="corporate-onboarding/anchor"
            element={<CorporateOnboardAnchor />}
          />

          <Route
            path="corporate-onboarding/counter-party"
            element={<CorporateOnboardCounterParty />}
          />
        </Route>
      </Route>

      {/* Relationship Manager */}
      <Route element={<RMPrivateRoute />} path="/rm">
        <Route element={<RelationShipManagerLayout />}>
          <Route index element={<Navigate to="./dashboard" />} />
          <Route path="dashboard" element={<RMDashboard />} />
          <Route
            path="customer-onboarding"
            element={<RMCustomerOnboarding />}
          />
          <Route
            path="rm-invoices"
            element={<RMInvoices />}
          />

          <Route
            path="rm-onboarding/anchor"
            element={<AnchorKYC roles="seller" />}
          />

          <Route
            path="rm-onboarding/counter-party"
            element={<BuyerKyc roles="buyer" />}
          />
        </Route>
      </Route>

      {/* Under Writer */}
      <Route element={<UnderWriterPrivateRoute />} path="/underwriter">
        <Route element={<UnderWriterLayout />}>
          <Route index element={<Navigate to="./limit" />} />
          <Route path="limit" element={<UnderwriterLimit />} />
          <Route path="my-profile" element={<UnderwriterMyProfile />} />
          {/* <Route
            path="customer-onboarding"
            element={<CorporateCustomerOnboarding />}
          />

          <Route
            path="corporate-onboarding/anchor"
            element={<CorporateOnboardAnchor />}
          />

          <Route
            path="corporate-onboarding/counter-party"
            element={<CorporateOnboardCounterParty />}
          /> */}
        </Route>
      </Route>

      {/* Invalid Routes */}
      <Route path="/not-found" element={<ErrorPage />} />
      <Route path="*" element={<Navigate to="/not-found" />} />
    </Route>
  )
);

// export default ;
