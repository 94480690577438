import React from "react";
import { BsSearch, BsDownload } from "react-icons/bs";
import AdminCard from "../Admin/AdminCard";
import { useEffect, useState } from "react";
import AdminDashboardTable from "../Lender/LenderTables/LenderBillLogdingTable";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getDisbursedData, getRepaid, uploadLenderRepayment } from "../../store/slices/lender.js";
import Loader from "../../layout/GenericLayout/Loader";
import PaymentPage from "../../Pages/PaymentPage";
import { toastMessage } from "../../layout/GenericLayout/ToastMessage";
import { Helmet } from "react-helmet";
import repaymentSample from "../../assests/sampleData/repayment_sample.xlsx"
import LiquidationTable from "./LenderTables/LiquidationTable.js";

const LenderDisbursed = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [customerBillData, setcustomerBillData] = useState("");
  console.log(customerBillData);
  const [RepaymentTableRows, setRepaymentTableRows] = useState([]);
  const [repaymentMetaData, setRepaymentMetaData] = useState([]);

  const repaidData = useSelector(
    (state) => state.lender.repaidData
  );

  const [tableQueryParams, setTableQueryParams] = useState({
    tableOffset: 0,
    tableLimit: 5,
    tableSort: "invoiceDate",
    tableOrder: -1,
    currentPage: 1,
    searchValue: ""
  });
  

  const [search, setSearch] = useState("");
  const [discountData, setDiscount] = useState(true);

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const uploadRepaymentData = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    dispatch(uploadLenderRepayment(formData))
      .unwrap()
      .then((response) => {
        toastMessage(response.success, "success")
      })
      .catch((error) => {
        console.log("error : ", error);
        toastMessage(error.message, "error");
      });
  };

  useEffect(() => {
    dispatch(getRepaid(tableQueryParams))
      .unwrap()
      .then((response) => {
        setRepaymentTableRows(response.items);
        setRepaymentMetaData(response.metadata);
      })
      .catch((error) => {
        console.log("error : ", error);
        toastMessage(error.message, "error");
      });
  }, [dispatch, tableQueryParams]);

  // const handleSearch=(e)=>{
  //   setTableQueryParams((prev)=> ({...prev, tableOffset:0, searchValue:e.target.value}));
  // }

  const handleSearch = () => {
    dispatch(getDisbursedData(search))
      .unwrap()
      .then((response) => {
        console.log(response);
        if (response?.items[0]?.invoiceStatus !== "Lodgement") {
          toastMessage("Invoice liquidation not initiated", "error");
        }
        setcustomerBillData(response.items[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDiscount = () => {
    setDiscount(!discountData);
  };

  console.log(customerBillData);
  return (
    <div
      className="d-flex flex-column gap-4"
      style={{
        backgroundColor: "white",
        padding: "2rem",
        borderRadius: "10px",
      }}
    >
      <Helmet>
        <title>Repayment</title>
      </Helmet>
      <div className="bg-white admin-dashboard-card-container p-3">
        <div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2 py-2">
          <div style={{ flex: 6, }}>
            <span className="dashboard-content-title">Repayment</span>
            <label
              style={{
                width: "100%",
                marginTop: "2rem",
                fontWeight: "bold",
                fontSize: "18px",
              }}
            >
              Enter Bill Reference Number
            </label>
            <div className="search-container d-flex align-items-center py-1 px-3 col-12 col-sm-4 ">
              <input
                className="search-input-element d-flex flex-grow-1"
                type="text"
                placeholder="Search"
                onChange={onChangeSearch}
                onKeyPress={onChangeSearch}
                value={search}
              />{" "}
              <BsSearch className="fs-5" onClick={handleSearch} />
            </div>
          </div>

          {/* <div style={{ flex: 6, }}>
            <div className="dashboard-content-title">Repayment</div>
            <div className="d-flex flex-column flex-sm-row align-items-center my-4">
              <div className="d-flex flex-column justify-content-center gap-3 col-12 col-sm-10">
                <div className="search-container d-flex align-items-center py-1 px-3 col-12 col-sm-8">
                  <input
                    className="search-input-element d-flex flex-grow-1"
                    type="text"
                    placeholder="Search with Invoice No"
                    value={tableQueryParams?.searchValue}
                    onChange={handleSearch}
                  />{" "}
                  <BsSearch className="fs-5" />
                </div>
              </div>
            </div>
          </div> */}

          <div className="d-flex align-items-center row" style={{ flex: 4, }}>
            <div className="col-12 m-0 p-0" style={{ display: "inline-block", textAlign: "center" }}>
              <label htmlFor="upload_invoice" className="form-label text-primary" style={{ cursor: "pointer", }}>Upload Repayment Invoices (.csv,.xls,.xlsx)</label>
              <input type="file" style={{ display: "none" }} onChange={uploadRepaymentData} id="upload_invoice" accept=".csv,.xls,.xlsx" />
            </div>
            <div className="m-0 p-0">
              <a href={repaymentSample}>
                <button type="button" className="btn btn-outline-danger">

                  Download Repayment Invoice Sample (.csv, .xls, .xlsx) &nbsp;&nbsp;&nbsp;
                  <BsDownload style={{ fontWeight: "600", fontSize: "2rem" }} />
                </button>
              </a>
            </div>
          </div>
        </div>


        {customerBillData !== "" && (
          <div className="details-container container p-3">
            <div className="row gy-md-4 gy-2">
              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="billing"
                  className="kyc-label-text form-label fw-semibold"
                >
                  CIF
                </label>
                <input
                  disabled
                  id="billing"
                  type="text"
                  name="billing"
                  value={customerBillData?.billReferenceNo}
                  className="kyc-input-field form-control"
                />
              </div>

              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="shipping"
                  className="kyc-label-text form-label fw-semibold"
                >
                  Anchor
                </label>
                <input
                  id="shipping"
                  disabled
                  type="text"
                  name="shipping"
                  className="kyc-input-field form-control"
                  value={customerBillData?.invoiceId}
                />
              </div>
              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="shipping"
                  className="kyc-label-text form-label fw-semibold"
                >
                  Other Party
                </label>
                <input
                  id="shipping"
                  disabled
                  type="text"
                  name="shipping"
                  className="kyc-input-field form-control"
                  value={customerBillData?.invoiceDate}
                />
              </div>
            </div>
            <div className="row gy-md-4 mt-2 gy-2">
              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="billing"
                  className="kyc-label-text form-label fw-semibold"
                >
                  Bill Type
                </label>
                <input
                  disabled
                  id="billing"
                  type="text"
                  name="billing"
                  className="kyc-input-field form-control"
                  value={customerBillData?.billType}
                />
              </div>

              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="shipping"
                  className="kyc-label-text form-label fw-semibold"
                >
                  Currency
                </label>
                <input
                  id="shipping"
                  disabled
                  type="text"
                  name="shipping"
                  className="kyc-input-field form-control"
                  value={customerBillData?.lodgementDate}
                />
              </div>
              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="shipping"
                  className="kyc-label-text form-label fw-semibold"
                >
                  Invoice Amount
                </label>
                <input
                  id="shipping"
                  disabled
                  type="text"
                  name="shipping"
                  className="kyc-input-field form-control"
                  value={customerBillData?.dueDate}
                />
              </div>
            </div>
            <div className="row gy-md-4 mt-2 gy-2">
              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="billing"
                  className="kyc-label-text form-label fw-semibold"
                >
                  Margin
                </label>
                <input
                  disabled
                  id="billing"
                  type="text"
                  name="billing"
                  className="kyc-input-field form-control"
                  value={customerBillData?.fees}
                />
              </div>

              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="shipping"
                  className="kyc-label-text form-label fw-semibold"
                >
                  Amount Financed (₹)
                </label>
                <input
                  id="shipping"
                  disabled
                  type="text"
                  name="shipping"
                  className="kyc-input-field form-control"
                  value={customerBillData?.interestAmount}
                />
              </div>
              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="shipping"
                  className="kyc-label-text form-label fw-semibold"
                >
                  Interest Rate (%)
                </label>
                <input
                  id="shipping"
                  disabled
                  type="text"
                  name="shipping"
                  className="kyc-input-field form-control"
                  value={customerBillData?.interestRate}
                />
              </div>
            </div>
            <div className="row gy-md-4 mt-2 gy-2">
              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="billing"
                  className="kyc-label-text form-label fw-semibold"
                >
                  Tenor(Days)
                </label>
                <input
                  disabled
                  id="billing"
                  type="text"
                  name="billing"
                  className="kyc-input-field form-control"
                  value={customerBillData?.amountFinanced}
                />
              </div>

              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="shipping"
                  className="kyc-label-text form-label fw-semibold"
                >
                  Interest Amount (₹)
                </label>
                <input
                  id="shipping"
                  disabled
                  type="text"
                  name="shipping"
                  className="kyc-input-field form-control"
                  value={customerBillData?.tenure}
                />
              </div>
              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="shipping"
                  className="kyc-label-text form-label fw-semibold"
                >
                  Processing Fees (%)
                </label>
                <input
                  id="shipping"
                  disabled
                  type="text"
                  name="shipping"
                  className="kyc-input-field form-control"
                  value={customerBillData?.discountedDate}
                />
              </div>
            </div>

            <div className="row gy-md-4 mt-2 gy-2">
              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="billing"
                  className="kyc-label-text form-label fw-semibold"
                >
                  Platform Fees (%)
                </label>
                <input
                  disabled
                  id="billing"
                  type="text"
                  name="billing"
                  className="kyc-input-field form-control"
                  value={customerBillData?.outstandingAmount}
                />
              </div>

              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="shipping"
                  className="kyc-label-text form-label fw-semibold"
                >
                  Stamping Charges (%)
                </label>
                <input
                  id="shipping"
                  disabled
                  type="text"
                  name="shipping"
                  className="kyc-input-field form-control"
                  value={customerBillData?.marginAmount}
                />
              </div>
              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="shipping"
                  className="kyc-label-text form-label fw-semibold"
                >
                  Insurance Charges (%)
                </label>
                <input
                  id="shipping"
                  disabled
                  type="text"
                  name="shipping"
                  className="kyc-input-field form-control"
                  value={customerBillData?.margin}
                />
              </div>
            </div>

            <div className="row gy-md-4 mt-2 gy-2">
              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="billing"
                  className="kyc-label-text form-label fw-semibold"
                >
                  Other Charges (%)
                </label>
                <input
                  disabled
                  id="billing"
                  type="text"
                  name="billing"
                  className="kyc-input-field form-control"
                  value={customerBillData?.disbursingAmount}
                />
              </div>

              {discountData == true && (
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="shipping"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Disbursing Amount
                  </label>
                  <input
                    id="shipping"
                    disabled
                    type="text"
                    name="shipping"
                    className="kyc-input-field form-control"
                    value={customerBillData?.outstandingRemainingAmount}
                  />
                </div>
              )}

              {discountData == true && (
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="shipping"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Disbursing Remaining Amount
                  </label>
                  <input
                    id="shipping"
                    disabled
                    type="text"
                    name="shipping"
                    className="kyc-input-field form-control"
                    value={customerBillData?.complianceCheck}
                  />
                </div>
              )}

              <div className="d-flex flex-row" style={{ padding: "2rem" }}>
                <div style={{ width: "20%", marginLeft: "60%" }}>
                  {discountData == true ? (
                    <button
                      style={{
                        background: "#719a57",
                        padding: "10px 30px",
                        color: "white",
                        border: "1px solid #719a57",
                        borderRadius: "7px",
                      }}
                      onClick={handleDiscount}
                    >
                      Liquidated
                    </button>
                  ) : (
                    <button
                      style={{
                        background: "#719a57",
                        padding: "10px 30px",
                        color: "white",
                        border: "1px solid #719a57",
                        borderRadius: "7px",
                      }}
                      onClick={handleDiscount}
                    >
                      Liquidate
                    </button>
                  )}
                </div>
                {/* <div style={{width:'20%'}}>
                    <PaymentPage amount={customerBillData?.outstandingAmount}>
                       <button style={{background:'white',padding:'10px 30px',color:'red',border:'2px solid red',borderRadius:'7px'}}>Disburse</button>                                 
                    </PaymentPage>
                   </div>*/ }
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="d-flex flex-column justify-content-between flex-wrap px-2 gap-2">
        <LiquidationTable
          className="lender-anchor-and-lender-mapped-table2 placeholder-wave"
          tableHeader={[
            { name: "Anchor" },
            { name: "Borrower" },
            { name: "Invoice Number" },
            { name: "External Invoice Number" },
            { name: "Bill Reference No" },
            { name: "Due Date" },
            { name: "Invoice Amount" },
            { name: "Status" },
            { name: "Outstanding Remaining Amount" }
          ]}
          tableRows={RepaymentTableRows}
          materialRequestMetaData={repaymentMetaData}
          tableQueryParams={tableQueryParams}
          setTableQueryParams={setTableQueryParams}
          isLoading={repaidData.isLoading}
        />
      </div>
    </div >
  );
};

export default LenderDisbursed;
